<template>
  <v-container>
    <ContractEditDialog ref="dialog" />
    <v-row v-if="!year">
      <v-progress-linear indeterminate />
    </v-row>
    <v-row v-if="year">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title> {{ year.name }} Contracts</v-toolbar-title>
            <v-spacer />
            <v-text-field
              v-model="search"
              single-line
              hide-details
              label="Search"
            />
            <template v-slot:extension>
              <v-spacer />
              <v-btn small text @click="downloadSpreadsheet()">
                <v-icon left small>fa-file-excel</v-icon>
                Download
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row justify="center" class="text-center">
                  <v-col cols="6" sm="4">
                    <div class="headline">
                      {{ signedContracts.length }}
                      ( {{ signedRevenue | currency }} )
                    </div>
                    <div class="body-1">
                      Signed
                      <span class="text-caption">
                        (
                        {{ (signedRevenue / signedStudentCount) | currency }}
                        / child )
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <div class="headline">
                      {{ unsignedContracts.length }}
                      ( {{ unsignedRevenue | currency }} )
                    </div>
                    <div class="body-1">
                      Unsigned

                      <span class="text-caption">
                        (
                        {{
                          (unsignedRevenue / unsignedStudentCount) | currency
                        }}
                        / child )
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <div class="headline">
                      {{ contracts.length }}
                      ( {{ revenue | currency }} )
                    </div>
                    <div class="body-1">
                      Total
                      <span class="text-caption">
                        ( {{ (revenue / studentCount) | currency }} / child )
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="loading">
                <v-progress-linear indeterminate />
              </v-col>

              <v-col cols="12" v-if="!loading">
                <v-data-table
                  dense
                  :items="sortedContracts"
                  :headers="headers"
                  :search="search"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>
                          <router-link
                            :to="{
                              name: 'familyShow',
                              params: { id: item.id },
                            }"
                          >
                            {{ item.familyName }}
                          </router-link>
                        </td>
                        <td class="text-right">
                          {{ item.tuition | currency }}
                        </td>
                        <td>{{ item.fullTimeNames }}</td>
                        <td>{{ item.partTimeNames }}</td>
                        <td class="text-center">
                          <v-icon
                            v-if="
                              item.tuitionAssistanceRequested &&
                              !item.tuitionAssistanceGranted
                            "
                            small
                            color="red darken-4"
                          >
                            fa-inbox
                          </v-icon>

                          <v-icon
                            v-if="
                              item.tuitionAssistanceRequested &&
                              item.tuitionAssistanceGranted
                            "
                            small
                            color="green darken-4"
                          >
                            fa-gift
                          </v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon
                            color="green darken-4"
                            small
                            v-if="item.isSigned"
                          >
                            fa-file-signature
                          </v-icon>
                        </td>
                        <td class="text-center">
                          <v-btn
                            x-small
                            dark
                            color="brown"
                            @click="editContract(item.id)"
                            class="mr-2"
                          >
                            <v-icon x-small left>fa-edit</v-icon>
                            Edit
                          </v-btn>

                          <ContractPDFGenerator
                            v-if="year && item.family"
                            :year="year"
                            :family="item.family"
                            :contract="item"
                            color="green darken-4"
                            x-small
                          >
                            <v-icon
                              x-small
                              :title="
                                'Download ' + item.familyName + ' Contract'
                              "
                              >fa-file-pdf</v-icon
                            >
                          </ContractPDFGenerator>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row justify="space-around" class="text-center">
              <LabeledData cols="12" sm="4" label="Assistance Given">
                <span class="headline">{{ assistance | currency }}</span>
              </LabeledData>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import XLSX from 'xlsx';
import {
  db,
  enrolledFamiliesInYear,
  calculatedNameForFamily,
  guardianNamesForFamily,
} from '@/lib/firestoredb';

import LabeledData from '@/components/LabeledData.vue';
import ContractPDFGenerator from '@/components/ContractPDFGenerator.vue';
import ContractEditDialog from '@/components/ContractEditDialog.vue';

export default {
  props: ['id'],

  data() {
    return {
      loading: true,
      search: '',
      year: null,
      families: [],
      contracts: [],
      enrollments: [],
      headers: [
        { text: 'Family', value: 'familyNameAndGuardians' },
        { text: 'Tuition', value: 'tuition', align: 'right' },
        { text: 'Full Time', value: 'fullTimeNames' },
        { text: 'Part Time', value: 'partTimeNames' },
        { text: 'Tuition Assistance', value: null, align: 'center' },
        { text: 'Signed', align: 'center', value: 'isSigned' },
        { text: 'Edit', align: 'center', value: null, sortable: false },
      ],
    };
  },
  computed: {
    revenue() {
      return _.sumBy(this.contracts, 'tuition');
    },

    assistance() {
      return _.sumBy(this.contracts, 'assistanceAmount');
    },

    signedRevenue() {
      return _.sumBy(this.signedContracts, 'tuition');
    },

    signedContracts() {
      return _.chain(this.contracts || [])
        .filter((c) => c.isSigned)
        .value();
    },

    signedStudentCount() {
      return _.chain(this.signedContracts)
        .map((c) => this.studentCountForContract(c))
        .sum()
        .value();
    },

    unsignedRevenue() {
      return _.sumBy(this.unsignedContracts, 'tuition');
    },

    unsignedContracts() {
      return _.chain(this.contracts || [])
        .filter((c) => !c.isSigned)
        .value();
    },

    unsignedStudentCount() {
      return _.chain(this.unsignedContracts)
        .map((c) => this.studentCountForContract(c))
        .sum()
        .value();
    },

    sortedContracts() {
      return _.chain(this.contracts || [])
        .map((c) => {
          const enrollments = this.enrollments.filter(
            (e) => e.familyID === c.id
          );

          const partTimers = enrollments.filter(
            (e) => e.enrollmentType === 'Part Time'
          );
          const fullTimers = enrollments.filter(
            (e) => e.enrollmentType === 'Full Time'
          );

          const family = this.families.find((f) => f.id === c.id);
          const calculatedName = family ? calculatedNameForFamily(family) : '';
          const guardianNames = family ? guardianNamesForFamily(family) : '';

          return Object.assign(c, {
            familyName: calculatedName ? calculatedName : c.familyName,
            familyNameAndGuardians: `${calculatedName} (${guardianNames})`,
            family,
            fullTimeNames: fullTimers.map((e) => e.studentName).join(', '),
            partTimeNames: partTimers.map((e) => e.studentName).join(', '),
          });
        })
        .sortBy('familyNameAndGuardians')
        .value();
    },
    studentCount() {
      return _.chain(this.contracts || [])
        .map((c) => this.studentCountForContract(c))
        .sum()
        .value();
    },
  },

  watch: {
    id: {
      immediate: true,
      async handler(id) {
        await Promise.all([
          this.$bind('year', db.collection('years').doc(id)),
          this.$bind(
            'contracts',
            db.collection('years').doc(id).collection('contracts')
          ),
          this.$bind(
            'enrollments',
            db.collection('years').doc(id).collection('enrollments')
          ),
        ]);

        const families = await enrolledFamiliesInYear(id);
        this.families = families;
        this.loading = false;
      },
    },
  },

  methods: {
    editContract(familyID) {
      this.$refs.dialog.open(familyID, this.year.id);
    },

    studentCountForContract(contract) {
      const decisionValues = Object.values(contract.studentDecisions);
      return _.filter(decisionValues, (v) => {
        return _.includes(['Part Time', 'Full Time'], v);
      }).length;
    },

    downloadSpreadsheet() {
      const sheetName = this.year.name + ' Contracts';
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: sheetName,
        Author: 'admin.villagefreeschool.org',
        CreatedDate: new Date(),
      };
      let data = [['Family', 'Signed', 'Tuition', 'Full Time', 'Part Time']];
      this.sortedContracts.forEach((c) => {
        data.push([
          c.familyName,
          c.isSigned ? 'Signed' : '',
          (c.tuition || 0).toFixed(2),
          c.fullTimeNames,
          c.partTimeNames,
        ]);
      });
      let ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      const safeName = sheetName.replace(/[\W_]+/g, '');
      XLSX.writeFile(wb, `${safeName}.xlsx`);
    },
  },

  components: {
    ContractEditDialog,
    ContractPDFGenerator,
    LabeledData,
  },
};
</script>

<template>
  <div>
    <v-row justify="space-around" dense>
      <v-col cols="12" sm="4">
        <v-text-field
          label="First Name"
          v-model.trim="local.firstName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('firstName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Last Name"
          v-model.trim="local.lastName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('lastName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <RelationshipDropdown
          v-model="local.relationship"
          :rules="[(v) => !!v || 'Required']"
          @input="update('relationship', $event)"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          label="Email Address"
          v-model.trim="local.email"
          :rules="[(v) => !!v || 'Required']"
          @input="update('email', $event)"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Cell Phone"
          v-model="local.cellPhone"
          v-mask="'###-###-####'"
          :rules="[(v) => !!v || 'Required']"
          @input="update('cellPhone', $event)"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Work Phone"
          v-model="local.workPhone"
          v-mask="'###-###-####'"
          @input="update('workPhone', $event)"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          label="Work/Other Email Addresses"
          rows="2"
          hint="Users logging in with any of these email addresses will be linked to your family automatically."
          v-model="local.otherEmails"
          @input="update('otherEmails', $event)"
        />
      </v-col>
      <v-col cols="12" v-if="index !== 0">
        <v-checkbox
          label="At Same Address"
          v-model="local.atSameAddress"
          @input="update('atSameAddres', $event)"
        />
      </v-col>
      <v-col cols="12" v-if="index === 0">
        <div style="min-height: 64px" />
      </v-col>
      <v-col cols="12" v-if="!local.atSameAddress">
        <v-text-field
          label="Address"
          v-model.trim="local.address1"
          :rules="[(v) => !!v || 'Required']"
          @input="update('address1', $event)"
        />
      </v-col>
      <v-col cols="12" v-if="!local.atSameAddress">
        <v-text-field
          label="Suite / Apt #"
          v-model.trim="local.address2"
          @input="update('address2', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6" v-if="!local.atSameAddress">
        <v-text-field
          label="City"
          v-model.trim="local.city"
          :rules="[(v) => !!v || 'Required']"
          @input="update('city', $event)"
        />
      </v-col>
      <v-col cols="6" sm="2" v-if="!local.atSameAddress">
        <v-text-field
          label="State"
          v-model.trim="local.state"
          :rules="[(v) => !!v || 'Required']"
          @input="update('state', $event)"
        />
      </v-col>
      <v-col cols="6" sm="4" v-if="!local.atSameAddress">
        <v-text-field
          label="ZIP Code"
          v-model.trim="local.zip"
          :rules="[(v) => !!v || 'Required']"
          @input="update('zip', $event)"
        />
      </v-col>
      <v-col>
        <v-text-field
          label="Occupation"
          v-model.trim="local.occupation"
          @input="update('occupation', $event)"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          label="Notes"
          hint="If needed, enter notes about custody or relationship status."
          rows="2"
          v-model="local.notes"
          @input="update('notes', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';

import RelationshipDropdown from '@/components/RelationshipDropdown.vue';

export default {
  props: ['value', 'index'],
  data() {
    return {};
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            firstName: '',
            lastName: '',
            relationship: null,
            cellPhone: '',
            workPhone: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            occupation: '',
            notes: '',
          };
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {
    RelationshipDropdown,
  },
};
</script>

<template>
  <v-dialog v-model="isOpen" persistent scrollable max-width="600px">
    <v-progress-linear v-if="loading" indeterminate />
    <v-form ref="form" @submit.prevent="save()">
      <v-card v-if="!loading">
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>
            {{ family.name }} {{ year.name }} Enrollment
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <!-- 
            Suggested Tuition Amounts
          -->
          <v-row justify="space-between" class="grey lighten-3 text-center">
            <v-col cols="12">
              <strong>Sliding Scale Tuition</strong>
              <div class="text-caption text--disabled">
                Based on your family's income
              </div>
            </v-col>

            <v-col cols="3">
              <div>Full Time</div>
              {{ fullTimeTuition | currency }}
            </v-col>

            <v-col cols="3">
              <div>Full Time Sibling</div>
              {{ siblingTuition | currency }}
            </v-col>

            <v-col cols="3">
              <div>Part Time</div>
              {{ partTimeTuition | currency }}
            </v-col>
          </v-row>

          <!--
            Attendance Decisions Area
          -->
          <v-row>
            <v-col cols="12" class="text-h6 font-weight-bold">
              Who's Attending This Year?
            </v-col>

            <v-col
              cols="12"
              :sm="12 / family.students.length"
              v-for="student in family.students"
              :key="student.id"
            >
              <EnrollmentTypeSelector
                v-model="studentDecisions[student.id]"
                :label="student.preferredName"
                :rules="[(v) => !!v || 'Required']"
              />
            </v-col>
          </v-row>

          <!--
            Annual Tution Figure
          -->
          <v-row
            align="end"
            justify="space-around"
            v-if="allAttendanceDecisionsMade"
          >
            <v-col cols="12" class="px-10 text-body-2 text--disabled">
              The Village Free School uses a sliding scale to ensure the school
              remains affordable to all. A suggested tuition based on your
              family's income and payment history ({{
                suggestedTuition | currency
              }}) is the default setting below. Please adjust up or down as
              necessary, with awareness that VFS staff depend on tuition for
              their livelihood, and current compensation does not include health
              insurance.
            </v-col>

            <v-col class="text-center text--disabled">
              <div class="text-caption">Over 10 Months</div>
              <div class="text-body-2">{{ (tuition / 10) | currency }}/mo</div>
            </v-col>

            <v-col
              :class="{
                'text-h3': true,
                'text-right': true,
                'red--text':
                  tuition < minTuition && !tuitionAssistanceRequested,
                'green--text':
                  tuition > slidingScaleTuition && tuition > minTuition,
              }"
            >
              <div class="text-overline text--disabled">
                Total {{ year.name }} Tuition
              </div>
              {{ tuition | currency }}
              <div
                v-if="tuition > slidingScaleTuition"
                class="text-caption green-text"
              >
                Thank you for support of the staff and VFS community!
              </div>
            </v-col>

            <v-col class="text-center text--disabled">
              <div class="text-caption">Over 12 Months</div>
              <div class="text-body-2">{{ (tuition / 12) | currency }}/mo</div>
            </v-col>
          </v-row>

          <!--
            Slider Interface
          -->
          <v-row v-if="allAttendanceDecisionsMade" dense>
            <v-col cols="11">
              <v-slider
                v-model="tuition"
                :min="500"
                :max="maxTuition"
                :rules="sliderRules"
                hint="Drag slider to adjust tuition amount"
                persistent-hint
              >
                <template v-slot:prepend>
                  <v-icon color="brown" @click="decrementTuition()">
                    fa-minus-circle
                  </v-icon>
                </template>
                <template v-slot:append>
                  <v-icon color="green darken-4" @click="incrementTuition()">
                    fa-plus-circle
                  </v-icon>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="6" v-if="isAdmin || tuition < minTuition">
              <v-checkbox
                label="Request Tuition Assistance"
                v-model="tuitionAssistanceRequested"
              />
            </v-col>
            <v-col cols="6" v-if="isAdmin || tuition < minTuition">
              <v-currency-field
                prefix="$"
                label="Tuition Assistance Amount"
                v-model="assistanceAmount"
                disabled
              />
            </v-col>
          </v-row>

          <v-row v-if="allAttendanceDecisionsMade && user && user.isAdmin">
            <v-col cols="6">
              <v-checkbox label="Signed Contract Received" v-model="isSigned" />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                label="Tuition Assistance Granted"
                v-model="tuitionAssistanceGranted"
              />
            </v-col>
          </v-row>

          <v-row v-if="user && user.isAdmin && contract">
            <v-col cols="12">
              <v-list dense two-line>
                <v-list-item v-if="contract.lastSavedBy">
                  <v-list-item-content>
                    <v-list-item-title>Last Saved By</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contract.lastSavedBy }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="contract.lastSavedAt">
                  <v-list-item-content>
                    <v-list-item-title>Last Saved At</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contract.lastSavedAt }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn small text color="error" @click="close()">Cancel</v-btn>
          <v-btn v-if="clearable" @click="clear()" text small color="error">
            Clear Registration
          </v-btn>
          <v-btn
            color="brown"
            :loading="saving"
            :disabled="!allAttendanceDecisionsMade"
            type="submit"
            class="white--text"
          >
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { yearDB, familyDB } from '@/lib/firestoredb';
import {
  tuitionForIncome,
  minimumTuitionForIncome,
  MaxYearOverYearChange,
  FullTime,
  PartTime,
} from '@/lib/tuitioncalc';

import EnrollmentTypeSelector from '@/components/EnrollmentTypeSelector.vue';

export default {
  data() {
    return {
      isOpen: false,
      loading: true,
      saving: false,

      familyID: null,
      family: null,

      yearID: null,
      year: null,

      contract: null,
      priorContract: null,
      enrollments: [],

      tuition: 0,
      assistanceAmount: 0,
      tuitionAssistanceRequested: false,
      tuitionAssistanceGranted: false,
      studentDecisions: {},
      isSigned: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin', 'priorYear']),

    /**
     * allAttendanceDecisionsMade returns true if all attendance fields
     * are completed. All other editing UI is hidden until this returns true.
     */
    allAttendanceDecisionsMade() {
      if (!this.family || !this.family.students) {
        return false;
      }
      const students = this.family.students || [];
      const decs = _.chain(this.studentDecisions).values().compact().value();
      return students.length > 0 && decs.length == students.length;
    },

    /**
     * attendanceDecisionsChangedFromPriorYear returns true when there is a
     * contract for the prior year and its enrollment choices are different
     * from this year.
     */
    attendanceDecisionsChangedFromPriorYear() {
      if (!this.priorContract) {
        return false;
      }
      if (!this.allAttendanceDecisionsMade) {
        return true;
      }
      return !_.isMatch(
        this.studentDecisions,
        this.priorContract.studentDecisions
      );
    },

    /**
     * decisionsChangedFromContract returns true if the decisions selections
     * differ from what is stored in the contract. If there is no contract
     * yet, this will always return true.
     */
    decisionsChangedFromContract() {
      if (!this.family || !this.family.students) {
        return false;
      }
      if (!this.contract) {
        return true;
      }
      const students = this.family.stuents || [];
      const decisions = _.get(this, 'studentDecisions', {});
      const contractDecisions = _.get(this, 'contract.studentDecisions', {});
      for (const student of students) {
        if (decisions[id] !== contractDecisions[id]) {
          return true;
        }
      }
      return false;
    },

    /**
     * tuitionChangedFromContract returns true if the tuition setting on this
     * page differs from the one saved on the contract.
     *
     */
    tuitionChangedFromContract() {
      const contractTution = _.get(this, 'contract.tuition', 0);
      const tution = _.get(this, 'tuition', 0);
      return tuition !== contractTution;
    },

    sliderRules() {
      if (this.tuitionAssistanceRequested) {
        return [];
      }
      if (this.tuition < this.minTuition) {
        return [(v) => 'Tuition Assistance Required'];
      }
      return [];
    },

    /**
     * clearable returns true when this contract is allowed to be cleared/deleted
     */
    clearable() {
      if (!this.contract) {
        return false;
      }
      if (!this.user || !this.user.isAdmin) {
        return false;
      }
      for (const student of this.family.students) {
        if (
          this.studentDecisions[student.id] &&
          this.studentDecisions[student.id].match(/Time/i)
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * slidingScaleTuition is the direct result of the sliding scale
     * algorithm for the selected family's income and the selected
     * year's pricing.
     */
    slidingScaleTuition() {
      const opts = this.tuitionOpts;
      opts.year = this.year;
      return tuitionForIncome(this.family.grossFamilyIncome, opts);
    },

    /**
     * minTuition computes the minimum tuition this family is allowed to pay
     * for this contract with the selected attendance levels.
     */
    minTuition() {
      const opts = this.tuitionOpts;
      opts.year = this.year;

      let t = this.slidingScaleTuition;
      if (this.family.grossFamilyIncome > this.year.maximumIncome) {
        t = tuitionForIncome(this.year.maximumIncome, opts);
      }

      if (!this.priorContract) {
        // When there's no prior contract, we don't need to calculate the
        // 10% limits.
        return t;
      }

      if (this.attendanceDecisionsChangedFromPriorYear) {
        // When the attendance decisions were changed from the prior year,
        // we can't calculate the 10% limits.
        return t;
      }

      const maxUp = this.priorContract.tuition * (1 + MaxYearOverYearChange);
      const maxDown = this.priorContract.tuition * (1 - MaxYearOverYearChange);

      if (t > maxUp) {
        return Math.round(maxUp);
      } else if (t < maxDown) {
        return Math.round(maxDown);
      } else {
        return t;
      }
    },

    /**
     * suggestedTuition returns the total tuition price that should be set when
     * the page first loads and no contract is saved.
     */
    suggestedTuition() {
      if (this.slidingScaleTuition < this.minTuition) {
        return this.minTuition;
      } else {
        return this.slidingScaleTuition;
      }
    },

    /**
     * maxTuition computes the upper end of the tuition amount slider.
     */
    maxTuition() {
      return Math.max(this.slidingScaleTuition, this.suggestedTuition) * 2;
    },

    /**
     * fullTimeTuition returns the sliding scale calculator value for a single
     * full-time student based on this family's gross income.
     */
    fullTimeTuition() {
      const opts = { fullTime: 1, partTime: 0, siblings: 0 };
      opts.year = this.year;
      return tuitionForIncome(this.family.grossFamilyIncome, opts);
    },

    /**
     * siblingTuition returns the sliding scale calculator value for each
     * additional full-time sibling based on this family's gross income.
     */
    siblingTuition() {
      const opts = { fullTime: 1, partTime: 0, siblings: 1 };
      opts.year = this.year;
      const total = tuitionForIncome(this.family.grossFamilyIncome, opts);
      return total - this.fullTimeTuition;
    },

    /**
     * partTimeTuition returns the sliding scale calculator value for each
     * part time studen based on this family's gross income.
     */
    partTimeTuition() {
      const opts = { fullTime: 0, partTime: 1, siblings: 0 };
      opts.year = this.year;
      return tuitionForIncome(this.family.grossFamilyIncome, opts);
    },

    /**
     * tuitionOpts builds the options object which is supplied to the
     * tuitionForIncome function. Specifically, it calculates how many
     * fullTime, partTime, and siblings should be included in the algorithm.
     * {
     *   fullTime: 1, partTime: 0, siblings: 1
     * }
     */
    tuitionOpts() {
      const opts = { fullTime: 0, partTime: 0, siblings: 0 };
      if (_.isEmpty(this.studentDecisions)) {
        return opts;
      }
      for (const studentID of Object.keys(this.studentDecisions)) {
        const enrollmentType = this.studentDecisions[studentID];
        switch (enrollmentType) {
          case FullTime: {
            if (opts.fullTime === 0) {
              opts.fullTime += 1;
            } else {
              opts.siblings += 1;
            }
            break;
          }
          case PartTime: {
            opts.partTime += 1;
            break;
          }
        }
      }
      return opts;
    },
  },

  watch: {
    studentDecisions: {
      deep: true,
      handler(newVal) {
        if (this.decisionsChangedFromContract) {
          this.tuition = this.suggestedTuition;
        } else if (this.contract) {
          this.tuition = this.contract.tuition;
        }
      },
    },
    tuition() {
      if (this.tuition < this.minTuition) {
        this.assistanceAmount = this.minTuition - this.tuition;
      } else {
        this.tuitionAssistanceRequested = false;
        this.assistanceAmount = 0;
      }
    },
  },

  methods: {
    /**
     * open is the primary entrypoint to this component. It sets
     * the familyID and yearID and triggers data-binding to the
     * Firestore database.
     */
    open(familyID, yearID) {
      this.familyID = familyID;
      this.yearID = yearID;

      this.loading = true;
      this.isOpen = true;
      Promise.all([
        this.$bind('year', yearDB.doc(this.yearID)),
        this.$bind('family', familyDB.doc(this.familyID)),
        this.bindContract(),
        this.bindPriorContract(),
        this.bindEnrollments(),
      ]).then(() => {
        this.loading = false;
      });
    },

    /**
     * close just dismisses the dialog without unbinding or
     * changing any of the bound data.
     */
    close() {
      this.isOpen = false;
    },

    bindContract() {
      return this.$bind(
        'contract',
        yearDB.doc(this.yearID).collection('contracts').doc(this.familyID)
      ).then(() => {
        if (this.contract) {
          this.tuition = this.contract.tuition || this.suggestedTuition;
          this.tuitionAssistanceRequested =
            !!this.contract.tuitionAssistanceRequested ||
            !!this.contract.assistanceAmount;
          this.tuitionAssistanceGranted = !!this.contract
            .tuitionAssistanceGranted;
          this.isSigned = this.contract.isSigned;
          this.assistanceAmount = this.contract.assistanceAmount;
          this.studentDecisions = { ...this.contract.studentDecisions };
        }
      });
    },

    bindPriorContract() {
      const priorYear = this.priorYear(this.yearID);
      if (!priorYear) {
        this.$unbind('priorContract');
        return Promise.resolve();
      }
      return this.$bind(
        'priorContract',
        yearDB.doc(priorYear.id).collection('contracts').doc(this.familyID)
      );
    },

    bindEnrollments() {
      return this.$bind(
        'enrollments',
        yearDB
          .doc(this.yearID)
          .collection('enrollments')
          .where('familyID', '==', this.familyID)
      );
    },

    /**
     * clear makes the contract behave as if it hasn't been previously saved
     */
    clear() {
      yearDB
        .doc(this.yearID)
        .collection('contracts')
        .doc(this.familyID)
        .delete();
      this.close();
    },

    /**
     * save saves the active contract to the database and updates enrollments
     * for all referenced students.
     */
    save() {
      const form = this.$refs.form;
      if (!form || !form.validate()) {
        return;
      }

      this.saving = true;
      return Promise.all([this.saveContract(), this.saveEnrollments()]).then(
        () => {
          this.saving = false;
          this.close();
        }
      );
    },

    /**
     * saveContract sets the contract record itself
     */
    saveContract() {
      const data = { ...this.contract };
      data.assistanceAmount = this.assistanceAmount;
      data.tuitionAssistanceRequested = this.tuitionAssistanceRequested;
      data.tuitionAssistanceGranted = this.tuitionAssistanceGranted;
      data.familyName = this.family.name;
      data.tuition = this.tuition;
      data.suggestedTuition = this.suggestedTuition;
      data.minTuition = this.minTuition;
      data.isSigned = this.isSigned;
      data.studentDecisions = this.studentDecisions;
      data.lastSavedBy = this.user.email;
      data.lastSavedAt = new Date().toString();

      // Clean up obsolete fields
      delete data.fullTimeTuition;
      delete data.partTimeTuition;
      delete data.adjustedTuition;

      yearDB
        .doc(this.yearID)
        .collection('contracts')
        .doc(this.familyID)
        .set(data);
    },

    /**
     * saveEnrollments iterates over each student, and sets or updates a
     * record in the enrollments collection for each student who's
     * enrollmentType is Part Time or Full Time.
     */
    saveEnrollments() {
      const promises = [];
      this.family.students.forEach((student) => {
        let enrollment = this.enrollments.find(
          (e) => e.studentID == student.id
        );

        if (!this.isAttending(student)) {
          if (enrollment) {
            // Delete enrollments that don't belong
            promises.push(
              yearDB
                .doc(this.yearID)
                .collection('enrollments')
                .doc(student.id)
                .delete()
            );
          }
          return;
        }

        promises.push(
          yearDB
            .doc(this.year.id)
            .collection('enrollments')
            .doc(student.id)
            .set({
              familyID: this.family.id,
              familyName: this.family.name,
              yearID: this.year.id,
              studentID: student.id,
              studentName: student.preferredName || student.firstName,
              enrollmentType: this.studentDecisions[student.id],
            })
        );
      });
      return Promise.all(promises);
    },

    /**
     * isAttending is a utility function which returns true if the
     * provided student is marked as having either Part Time or
     * Full Time attendance in the current year.
     */
    isAttending(student) {
      switch (this.studentDecisions[student.id]) {
        case FullTime:
          return true;
        case PartTime:
          return true;
        default:
          return false;
      }
    },

    /**
     * incrementTuition is the handler for the + button on the slider control.
     * It bumps the tuition number up by $250.
     */
    incrementTuition() {
      const quotient = this.tuition / 50;
      this.tuition = Math.round(quotient) * 50 + 50;
    },

    /**
     * decrementTuition is the handler for the - button on the slider control.
     * It bumps the tuition number down by $250.
     */
    decrementTuition() {
      const quotient = this.tuition / 50;
      this.tuition = Math.round(quotient) * 50 - 50;
    },
  },
  firestore: {
    years: yearDB.where('isAcceptingRegistrations', '==', true),
  },
  components: {
    EnrollmentTypeSelector,
  },
};
</script>

<template>
  <v-dialog v-model="isOpen" fullscreen scrollable>
    <v-form ref="form" @submit.prevent="save()">
      <v-card :loading="loading" height="100%">
        <v-toolbar color="green darken-4" dark class="flex-grow-0">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fill-height>
            <v-row justfy="center" align="center">
              <v-col cols="12">
                <v-progress-linear v-if="loading" indeterminate />

                <FamilyForm
                  v-if="!loading"
                  v-model="family"
                  @canceled="$emit('input', false)"
                  @saved="$emit('saved', family)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-if="false"> </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import FamilyForm from '@/components/FamilyForm.vue';

// import firebase from 'firebase/app';
import { familyDB, saveFamily } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      title: 'Edit Family Profile',
      isOpen: false,
      loading: false,
      saving: false,
      family: null,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    openNew() {
      this.title = 'New Family Profile';
      this.family = {
        id: familyDB.doc().id,
        name: '',
        guardians: [
          {
            email: this.user.email,
          },
        ],
      };
      this.isOpen = true;
    },
    open(id) {
      this.title = 'Edit';
      this.loading = true;
      this.isOpen = true;
      familyDB
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.family = Object.assign({}, doc.data());
            this.family.id = doc.id;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearBlankCollections() {
      // Students
      this.family.students = _.filter(
        this.family.students || [],
        (s) => !_.isEmpty(s)
      );
      // Guardians
      this.family.guardians = _.filter(
        this.family.guardians || [],
        (g) => !_.isEmpty(g)
      );
      // Emergency Contacts
      this.family.emergencyContacts = _.filter(
        this.family.emergencyContacts || [],
        (e) => !_.isEmpty(e)
      );
      // MedicalProviders
      this.family.medicalProviders = _.filter(
        this.family.medicalProviders || [],
        (m) => !_.isEmpty(m)
      );
    },
    save() {
      this.clearBlankCollections();
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = document.querySelector(
            '.v-input .v-messages.error--text:first-of-type'
          );
          if (el) {
            el.scrollIntoView(true);
          }
        });
        return;
      }
      const vm = this;
      vm.saving = true;
      saveFamily(vm.family)
        .then(() => {
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    FamilyForm,
  },
};
</script>

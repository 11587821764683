<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-form ref="form" @submit.prevent="save()">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>New User</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <UserForm
            v-if="isOpen && user"
            v-model="user"
            @canceled="$emit('input', false)"
            @saved="$emit('saved', user)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserForm from '@/components/UserForm.vue';

// import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      saving: false,
      isOpen: false,
      user: null,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      this.user = {};
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const vm = this;
      vm.saving = true;
      db.collection('users')
        .doc(vm.user.email)
        .set(vm.user)
        .then(() => {
          vm.$emit('saved', vm.user);
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    UserForm,
  },
};
</script>

<template>
  <v-container fill-height>
    <!-- LOADER -->
    <v-row v-if="loading">
      <v-col cols="12">
        <v-progress-linear indeterminate />
      </v-col>
    </v-row>
    <!-- END LOADER -->

    <!-- CREATE FAMILY PROMPT -->
    <v-row v-if="!loading && !family" justify="center" class="text-center">
      <v-col cols="12" sm="10" md="6" class="body-1">
        <div class="display-1">Create a New Family Profile?</div>
        <p>
          You logged in with the email address
          <strong>
            {{ user.email }}
          </strong>
          <br />
          This email address is not yet associated with a family profile.
        </p>
        <p>
          If you're a new family, welcome! Please create your family's profile.
        </p>
        <p>
          <v-btn color="green darken-4" large dark @click="newFamily()">
            <v-icon left>fa-plus-square</v-icon>
            Create Family Profile
          </v-btn>
        </p>
        <p class="caption red--text">
          If you're a returning family, or another parent or guardian has
          already entered your family's info, ask them to make sure the email
          address above is added to the profile they created.
        </p>
      </v-col>
    </v-row>
    <!-- END CREATE FAMILY PROMPT -->

    <!-- EXISTING FAMILY DISPLAY -->
    <v-row v-if="!loading && family">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title class="title">
              <h1 class="title">{{ family.name }}</h1>
            </v-toolbar-title>
            <v-spacer />
            <v-btn small color="brown" @click="edit()">
              <v-icon small left> fas fa-edit </v-icon>
              Edit
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <!-- Students -->
            <v-row>
              <v-col
                cols="12"
                :sm="Math.max(3, 12 / family.students.length)"
                v-for="(s, i) in family.students"
                :key="'student' + i"
              >
                <v-card flat>
                  <v-card-text>
                    <Student :student="s" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- End Students -->

            <v-divider />

            <!-- Parents and Guardians -->
            <v-row>
              <v-col cols="12" class="title"> Parents and Guardians </v-col>
              <v-col
                cols="12"
                :sm="Math.max(3, 12 / family.guardians.length)"
                v-for="(g, i) in family.guardians"
                :key="'guardian' + i"
              >
                <v-card flat>
                  <v-card-text>
                    <Guardian :guardian="g" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- End Parents and Guardians -->

            <v-divider />

            <!-- Emergency Contacts -->
            <v-row v-if="family && emergencyContacts">
              <v-col cols="12" class="title"> Emergency Contacts </v-col>

              <v-col cols="12">
                <v-data-table
                  :items="emergencyContacts"
                  :headers="emergencyContactHeaders"
                  disable-pagination
                  hide-default-footer
                >
                </v-data-table>
              </v-col>
            </v-row>
            <!-- End Emergency Contacts -->

            <!-- Pickup List -->
            <v-row>
              <v-col cols="12" class="title"> Pick Up List </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-text style="white-space: pre" class="body-2">{{
                    family.pickupList
                  }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- End Pickup List -->

            <!-- Medical and Insurance -->
            <v-row>
              <v-col cols="12" class="title"> Insurance </v-col>

              <LabeledData
                v-if="family.medicalInsuranceProvider"
                cols="12"
                sm="6"
                md="3"
                label="Insurance Provider"
              >
                {{ family.medicalInsuranceProvider }}
              </LabeledData>

              <LabeledData
                v-if="family.medicalInsuranceNameOfPrimaryInsured"
                cols="12"
                sm="6"
                md="3"
                label="Name of Primary Insured"
              >
                {{ family.medicalInsuranceNameOfPrimaryInsured }}
              </LabeledData>

              <LabeledData
                cols="12"
                sm="6"
                md="3"
                v-if="family.medicalInsurancePolicyNumber"
                label="Policy Number"
              >
                {{ family.medicalInsurancePolicyNumber }}
              </LabeledData>

              <LabeledData
                cols="12"
                sm="6"
                md="3"
                v-if="family.medicalInsuranceGroupNumber"
                label="Group Number"
              >
                {{ family.medicalInsuranceGroupNumber }}
              </LabeledData>

              <v-col cols="12" class="title" v-if="family.medicalProviders">
                Medical Providers
              </v-col>

              <v-col cols="12" v-if="family.medicalProviders">
                <v-data-table
                  :items="family.medicalProviders"
                  :headers="medicalProviderHeaders"
                  disable-pagination
                  hide-default-footer
                />
              </v-col>
            </v-row>
            <!-- End Medical and Insurance -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="family && user && user.isAdmin" justify="center">
      <v-col cols="12" md="4" class="text-center">
        <FamilyDeleteDialog :familyID="family.id" />
      </v-col>
    </v-row>
    <FamilyEditDialog ref="editDialog" @saved="family = $event" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

// import firebase from 'firebase/app';
import { familyDB } from '@/lib/firestoredb';

import Guardian from '@/components/Guardian.vue';
import LabeledData from '@/components/LabeledData.vue';
import FamilyEditDialog from '@/components/FamilyEditDialog.vue';
import Student from '@/components/Student.vue';
import FamilyDeleteDialog from '@/components/FamilyDeleteDialog.vue';

export default {
  props: ['id'],
  created() {
    this.bindFamily();
  },
  data() {
    return {
      loading: false,
      family: null,
      emergencyContactHeaders: [
        { text: '#', value: 'order' },
        { text: 'Name', value: 'name' },
        { text: 'Relationship', value: 'relationship' },
        { text: 'Cell', value: 'cellPhone' },
        { text: 'Work', value: 'workPhone' },
        { text: 'Notes', value: 'notes' },
      ],
      medicalProviderHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Office', value: 'office' },
        { text: 'Phone', value: 'phoneNumber' },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['myFamilyID']),
    emergencyContacts() {
      return this.family.emergencyContacts.map((c, i) => {
        c.order = i + 1;
        c.name = c.firstName + ' ' + c.lastName;
        return c;
      });
    },
  },
  watch: {
    id() {
      this.bindFamily();
    },
    myFamilyID() {
      this.bindFamily();
    },
  },
  methods: {
    newFamily() {
      this.$refs.editDialog.openNew();
    },
    edit() {
      this.$refs.editDialog.open(this.family.id);
    },
    bindFamily() {
      let id = this.id; // this.id is the prop we get from the URL path param
      if (!this.id) {
        // No id prop, so we initialize based on the myFamilyID getter
        id = this.myFamilyID;
      }
      if (id) {
        this.loading = true;
        this.$bind('family', familyDB.doc(id))
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  components: {
    FamilyDeleteDialog,
    FamilyEditDialog,
    Guardian,
    LabeledData,
    Student,
  },
};
</script>

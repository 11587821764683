<template>
  <div class="grey lighten-2 pa-4">
    <div class="text-h5 mb-2">Sliding Scale</div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-right">Income</th>
          <th class="text-right">Full Time</th>
          <th class="text-right">Part Time</th>
          <th class="text-right">2 Siblings Full Time</th>
          <th class="text-right">3 Siblings Full Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="income in incomeAmounts" :key="income">
          <td class="text-right">{{ income | currency }}</td>
          <td class="text-right">
            {{ tuitionForIncome(income, { fullTime: 1 }) | currency }}
          </td>
          <td class="text-right">
            {{ tuitionForIncome(income, { partTime: 1 }) | currency }}
          </td>
          <td class="text-right">
            {{
              tuitionForIncome(income, { fullTime: 1, siblings: 1 }) | currency
            }}
          </td>
          <td class="text-right">
            {{
              tuitionForIncome(income, { fullTime: 1, siblings: 2 }) | currency
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { tuitionForIncome } from '@/lib/tuitioncalc';

export default {
  props: {
    year: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    incomeAmounts() {
      const increment = 5000;
      const amounts = [];
      const maxIncome = (_.get(this, 'year.maximumIncome') || 150000) * 2;
      for (
        let income = 10000;
        income < maxIncome + increment;
        income = income + increment
      ) {
        amounts.push(income);
      }
      return amounts;
    },
  },
  methods: {
    tuitionForIncome(income, opts) {
      return tuitionForIncome(income, { ...opts, year: this.year });
    },
  },
};
</script>

<template>
  <v-list-item @click="download()">
    <v-list-item-action>
      <v-icon>fa-file-pdf</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :loading="generating">
        {{ year.name }} Directory
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { db, enrolledFamiliesInYear } from '@/lib/firestoredb.js';
import {
  dataURLFromImagePath,
  PDF_STYLES,
  PDF_DEFAULT_STYLE,
} from '@/lib/pdfutil.js';

export default {
  props: ['year'],

  async created() {
    this.fetchData();
    this.logo = await dataURLFromImagePath('/VFSLogo.png');
  },

  data() {
    return {
      generating: false,
      logo: '',
      students: [],
      families: [],
    };
  },

  computed: {
    sortedStudents() {
      return _.sortBy(this.students || [], 'lastName');
    },
  },

  methods: {
    async download() {
      this.generating = true;

      let families = await enrolledFamiliesInYear(this.year.id);
      families = _.sortBy(families, (f) => _.get(f, 'guardians[0].lastName'));
      const familyStacks = _.map(families, (f) => {
        const content = this.familyContent(f);
        return {
          stack: content,
          unbreakable: true,
        };
      });
      const fileName = `VFS ${this.year.name} Family Directory`;
      pdfMake
        .createPdf({
          content: [...this.titlePage(), ...familyStacks],
          styles: PDF_STYLES,
          defaultStyle: PDF_DEFAULT_STYLE,
          footer: this.pdfFooter,
        })
        .download(fileName);
      this.generating = false;
    },

    titlePage() {
      return [
        {
          image: this.logo,
          width: 500,
          margin: [0, 200, 0, 0],
        },
        {
          text: this.year.name + ' Student Directory',
          style: 'title',
          margin: [110, 20, 0, 200],
          pageBreak: 'after',
        },
      ];
    },

    /**
     * familyContent returns an array of pdfmake content objects for a single
     * family.
     */
    familyContent(family) {
      const content = [];

      // Family Title
      content.push({
        text: `${family.name}`,
        style: 'subtitle',
        margin: [0, 20, 0, 0],
      });

      // Parents Heading
      for (const g of family.guardians) {
        content.push({
          text: `${g.firstName} ${g.lastName} (${g.relationship}) cell: ${g.cellPhone} email: ${g.email}`,
        });
      }

      // Students Columns
      const students = family.students || [];
      content.push({
        margin: [25, 10, 25, 10],
        columns: _.map(family.students, (s) => {
          const birthday = moment(s.birthdate).format('MMM Do');
          const age = moment().diff(s.birthdate, 'years', false);
          return {
            stack: [
              {
                text: `${s.preferredName} ${s.lastName} (${age})`,
                bold: true,
              },
              { text: `Birthday: ${birthday}`, fontSize: 9 },
            ],
          };
        }),
      });

      // Line Break After Family
      content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 1,
          },
        ],
      });

      return content;
    },

    /**
     * pdfFooter is a callback which is called during rendering of each page
     * of the PDF. It generates a page-specific footer for the document.
     */
    pdfFooter(currentPage, pageCount) {
      if (currentPage == 1) {
        return {};
      }
      return {
        margin: [15, 0, 15, 0],
        columns: [
          {
            text: `VFS ${this.year.name} Student Directory`,
            style: 'caption',
            alignment: 'left',
          },
          {
            text: `Generated ${moment().format('MMMM Do YYYY, h:mm:ssa')}`,
            style: 'caption',
            width: '50%',
            alignment: 'center',
          },
          {
            text: `${currentPage}/${pageCount}`,
            style: 'caption',
            alignment: 'right',
          },
        ],
      };
    },

    fetchData() {
      this.$bind('students', db.collection('students'));
      this.generating = true;
      this.$bind('families', db.collection('families'));
    },
  },
};
</script>

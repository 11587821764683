<template>
  <v-select
    :label="label"
    :rules="rules"
    :value="value"
    @input="$emit('input', $event)"
    :items="choices"
  />
</template>

<script>
export default {
  props: ['value', 'label', 'rules'],
  data() {
    return {
      choices: ['Not Attending', 'Part Time', 'Full Time'],
    };
  },
};
</script>

<template>
  <v-container>
    <TuitionSettingsWidget v-if="isAdmin" />

    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <th class="text-right">Income</th>
              <th class="text-right">Students</th>
              <th class="text-right">Full Timers</th>
              <th class="text-right">Part Timers</th>
              <th class="text-right">Siblings</th>
              <th class="text-right">Typical Assistance Discount</th>
              <th class="text-right">Old Revenue</th>
              <th class="text-right">New Revenue</th>
            </thead>
            <tbody>
              <tr v-for="(row, i) in attendanceStats" :key="i">
                <td class="text-right">{{ row.income | currency }}</td>
                <td class="text-right">
                  {{ row.fullTimeCount + row.partTimeCount + row.siblingCount }}
                </td>
                <td class="text-right">{{ row.fullTimeCount }}</td>
                <td class="text-right">{{ row.partTimeCount }}</td>
                <td class="text-right">{{ row.siblingCount }}</td>
                <td class="text-right">
                  <span v-if="row.averageAssistance">{{
                    row.averageAssistance | percent
                  }}</span>
                </td>
                <td class="text-right">
                  {{ oldTuitionRowRevenue(row) | currency }}
                </td>
                <td class="text-right">
                  {{ newTuitionRowRevenue(row) | currency }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">
                  <div class="subtitle-1">{{ medianIncome | currency }}</div>
                  <div class="overline">Median</div>
                </th>
                <th class="text-right">
                  <div class="subtitle-1">{{ totalStudents }}</div>
                  <div class="overline">Total</div>
                </th>
                <th colspan="4"></th>
                <th class="text-right">
                  <div class="display-1">
                    {{ oldTuitionRevenue | currency }}
                  </div>
                  <div class="overline">Old Tuition Revenue</div>
                </th>
                <th class="text-right">
                  <div class="display-1">
                    <span
                      :class="
                        newTuitionRevenue < oldTuitionRevenue
                          ? 'red--text'
                          : 'green--text'
                      "
                      >{{ newTuitionRevenue | currency }}</span
                    >
                  </div>
                  <div class="overline">New Tuition Revenue</div>
                  <div class="overline">
                    {{ (newTuitionRevenue - oldTuitionRevenue) | currency }}
                  </div>
                </th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="js">
import { mapGetters, mapState } from 'vuex';

import TuitionSettingsWidget from '@/components/TuitionSettingsWidget.vue';

export default {
  data() {
    return {
      attendanceStats: [
        { income: 10000, fullTimeCount: 2, partTimeCount: 2, siblingCount: 0, averageAssistance: 0.8 },
        { income: 30000, fullTimeCount: 6, partTimeCount: 8, siblingCount: 0, averageAssistance: 0.75 },
        { income: 50000, fullTimeCount: 2, partTimeCount: 0, siblingCount: 0, averageAssistance: 0.3 },
        { income: 65000, fullTimeCount: 4, partTimeCount: 4, siblingCount: 0, averageAssistance: 0.1 },
        { income: 85000, fullTimeCount: 2, partTimeCount: 0, siblingCount: 0, averageAssistance: 0 },
        { income: 95000, fullTimeCount: 8, partTimeCount: 0, siblingCount: 4, averageAssistance: 0 },
        { income: 115000, fullTimeCount: 4, partTimeCount: 0, siblingCount: 2, averageAssistance: 0 },
        { income: 130000, fullTimeCount: 2, partTimeCount: 0, siblingCount: 2, averageAssistance: 0 },
        { income: 150000, fullTimeCount: 2, partTimeCount: 2, siblingCount: 0, averageAssistance: 0 },
        { income: 180000, fullTimeCount: 4, partTimeCount: 4, siblingCount: 0, averageAssistance: 0  },
      ],
    };
  },
  computed: {
    ...mapState([
      'allowSiblingDiscountForPartTime',
      'legacyBaseTuition',
      'minimumIncome',
      'maximumIncome',
      'maxYearOverYearChange',
      'siblingDiscountFactor',
      'partTimeDiscountFactor'
    ]),
    ...mapGetters(['baseTuitionForIncome', 'isAdmin']),
    years() {
      const results = [];
      return results;
    },
    totalStudents() {
      var tot = 0;
      for (const row of this.attendanceStats) {
        tot = tot + (row.fullTimeCount || 0) +  (row.partTimeCount || 0) + (row.siblingCount || 0);
      }
      return tot;
    },
    /**
     * medianIncome calculates the median income based on the numbers of attendees at each level
     */
    medianIncome() {
      const incomes = [];
      for (const row of this.attendanceStats) {
        const studentCount = (row.fullTimeCount || 0) +  (row.partTimeCount || 0) + (row.siblingCount || 0);
        for (var i = 0; i < studentCount; i++) {
          incomes.push(row.income);
        }
      }
      incomes.sort();
      var half = Math.floor(incomes.length / 2);
      if (incomes.length % 2) return incomes[half];
      return (incomes[half-1] + incomes[half]) / 2.0;
    },
    oldTuitionRevenue() {
      var revenue = 0;
      for (const row of this.attendanceStats) {
        revenue += this.oldTuitionRowRevenue(row);
      }
      return revenue;

    },
    newTuitionRevenue() {
      var revenue = 0;
      for (const row of this.attendanceStats) {
        revenue += this.newTuitionRowRevenue(row);

      }
      return revenue;
    }
  },
  watch: {
  },
  methods: {
    oldTuitionRowRevenue(row) {
      var rowRev = 0;
      const base = this.legacyBaseTuition;
      rowRev += (row.fullTimeCount || 0) * base;
      rowRev += (row.partTimeCount || 0) * base * this.partTimeDiscountFactor;
      rowRev += (row.siblingCount || 0) * base * this.siblingDiscountFactor;
      // Reduce revenue by assistance amount
      rowRev = rowRev * (1-row.averageAssistance);
      return rowRev;
    },
    newTuitionRowRevenue(row) {
      var rowRev = 0;
      const base = this.baseTuitionForIncome(row.income);
      rowRev += (row.fullTimeCount || 0) * base;
      rowRev += (row.partTimeCount || 0) * base * this.partTimeDiscountFactor;
      rowRev += (row.siblingCount || 0) * base * this.siblingDiscountFactor;
      return rowRev;

    },
  },
  components: {
    TuitionSettingsWidget,
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="headline">
        <h1>Market Rate Surveys</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Income</th>
              <th>Full Time</th>
              <th>Part Time</th>
              <th>Sibling</th>
              <th>Feedback</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="survey in surveys" :key="survey.id">
              <td>{{ survey.income | currency }}</td>
              <td>
                <span v-if="survey.fullTimeCount">
                  {{ survey.fullTimeTuition | currency }}
                  x {{ survey.fullTimeCount }}
                </span>
              </td>
              <td>
                <span v-if="survey.partTimeCount">
                  {{ survey.partTimeTuition | currency }}
                  x {{ survey.partTimeCount }}
                </span>
              </td>
              <td>
                <span v-if="survey.siblingCount">
                  {{ survey.siblingTuition | currency }}
                  x {{ survey.siblingCount }}
                </span>
              </td>
              <td>{{ survey.marketRateFeedback }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="headline">
        <h1>Existing Family Rate Surveys</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Income</th>
              <th>Prior Tuition</th>
              <th>New Tuition</th>
              <th>New Tuition (Adjusted)</th>
              <th>Acceptable?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="survey in surveys" :key="survey.uid">
              <td>{{ survey.income | currency }}</td>
              <td>{{ survey.priorTuition | currency }}</td>
              <td>{{ survey.nextYearTuition | currency }}</td>
              <td>{{ survey.nextYearAdjustedTuition | currency }}</td>
              <td>
                <span v-if="survey.paymentScheduleAcceptable == 'yes'">
                  <v-icon>fa-check</v-icon>
                </span>
                <span v-if="survey.paymentScheduleAcceptable == 'yesPlus'">
                  <v-icon color="green">fa-check</v-icon>
                  <v-icon color="green">fa-plus</v-icon>
                </span>
                <span v-if="survey.paymentScheduleAcceptable == 'no'">
                  <v-icon color="red">fa-ban</v-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="headline">
        <h1>Intent to Returns</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Family Name</th>
              <th>Email Address</th>
              <th>Planning to Return</th>
              <th>Feedback</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in intentToReturns" :key="r.uid">
              <td>{{ r.receivedAt.toDate() }}</td>
              <td>{{ r.familyName }}</td>
              <td>{{ r.emailAddress }}</td>
              <td>{{ r.planningToReturn }}</td>
              <td>{{ r.feedback }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from '@/lib/firestoredb';

export default {
  created() {
    this.fetchData();
  },
  data() {
    return {
      surveys: [],
      intentToReturns: [],
    };
  },
  methods: {
    fetchData() {
      this.surveys = [];
      db.collection('advertisedRateSurveys')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.surveys.push(doc.data());
          });
        });
      this.intentToReturns = [];
      db.collection('intentToReturns')
        .orderBy('receivedAt', 'asc')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.intentToReturns.push(doc.data());
          });
        });
    },
  },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueCurrencyFilter from 'vue-currency-filter';
import VCurrencyField from 'v-currency-field';
import '@babel/polyfill';

// Vue The Mask (text field masking)
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

// Firestore
import { firestorePlugin } from 'vuefire';
Vue.use(firestorePlugin);

Vue.config.productionTip = false;

Vue.filter('percent', function(val) {
  return parseInt(val * 100) + '%';
});

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <v-row>
    <v-col cols="12">
      <h1 class="title-2">Family Profile</h1>
      <pre>{{ local.id }}</pre>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Family Nickname</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            ref="familyName"
            label="What Should We Call Your Family?"
            hint="Examples: 'Smith Family' or 'The Smiths'"
            v-model.trim="local.name"
            :rules="[(v) => !!v || 'Required']"
            @input="update('name', $event)"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <!-- Students -->
    <v-col cols="12">
      <h2 class="subtitle">Students</h2>
      <span>
        <strong>Instructions</strong>: Enter at least the children who are
        planning to attend Village Free School. It is helpful for the staff to
        know the names of siblings, so consider entering children who are not
        attending as well.
      </span>
    </v-col>

    <v-col
      cols="12"
      v-for="(student, i) in local.students"
      :key="'student' + i"
    >
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Student #{{ i + 1 }}</v-toolbar-title>
          <v-spacer />
          <v-btn x-small fab v-if="i >= minStudents" @click="removeStudent(i)">
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <StudentForm
            :index="i"
            v-model="local.students[i]"
            @input="update('students', local.students)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" height="100%">
      <v-card height="100%">
        <v-toolbar color="grey" dark>
          <v-toolbar-title
            >Student #{{ local.students.length + 1 }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text color="grey">
          <v-row justify="center">
            <v-btn color="green darken-4" dark @click="addStudent()"
              >Enter Student / Child #{{ local.students.length + 1 }}</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Students -->

    <v-col cols="12">
      <v-divider />
    </v-col>

    <!-- Parents and Guardians -->
    <v-col cols="12">
      <h2 class="subtitle">Parents and Guardians</h2>
      <span>Enter all parents and legal guardians.</span>
    </v-col>

    <v-col
      cols="12"
      md="6"
      v-for="(guardian, i) in local.guardians"
      :key="'guardian' + i"
    >
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Parent / Guardian #{{ i + 1 }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            x-small
            fab
            v-if="i >= minGuardians"
            @click="removeGuardian(i)"
          >
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <GuardianForm
            :index="i"
            v-model="local.guardians[i]"
            @input="update('guardians', local.guardians)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" height="100%">
      <v-card height="100%">
        <v-toolbar color="grey" dark>
          <v-toolbar-title
            >Parent / Guardian #{{
              local.guardians.length + 1
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text color="grey">
          <v-row justify="center">
            <v-btn color="green darken-4" dark @click="addGuardian()"
              >Enter Parent / Guardian #{{ local.guardians.length + 1 }}</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Parents and Guardians -->

    <v-col cols="12">
      <v-divider />
    </v-col>

    <!-- Pick Up List -->
    <v-col cols="12">
      <h2 class="subtitle">Pick Up List</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>
            Additional Adults Authorized to Pick Up
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>
            In addition to the parents/guardians listed above, please name all
            other adults who are authorized to pick children up from school.
          </p>
          <v-textarea
            label="Pick Up List"
            v-model="local.pickupList"
            @input="update('pickupList', $event)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <!-- End Pick Up List -->

    <v-col cols="12">
      <v-divider />
    </v-col>

    <!-- Emergency Contacts -->
    <v-col cols="12">
      <h2 class="subtitle">Emergency Contacts</h2>
      <span>
        Please enter at least {{ minEmergencyContacts }} emergency contacts.
      </span>
    </v-col>

    <v-col
      cols="12"
      md="6"
      v-for="(contact, i) in local.emergencyContacts"
      :key="'emergencyContact' + i"
    >
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Emergency Contact #{{ i + 1 }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            x-small
            fab
            v-if="i >= minEmergencyContacts"
            @click="removeEmergencyContact(i)"
          >
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn
                x-small
                color="brown"
                dark
                v-if="canCopyGuardian(i)"
                @click="copyGuardianToContact(i)"
              >
                Copy from Parent / Guardian #{{ i + 1 }}
              </v-btn>
            </v-col>
          </v-row>
          <EmergencyContactForm
            v-model="local.emergencyContacts[i]"
            @input="update('emergencyContacts', local.emergencyContacts)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" height="100%">
      <v-card height="100%">
        <v-toolbar color="grey" dark>
          <v-toolbar-title
            >Emergency Contact #{{
              local.emergencyContacts.length + 1
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text color="grey">
          <v-row justify="center">
            <v-btn color="green darken-4" dark @click="addEmergencyContact()"
              >Enter Emergency Contact #{{
                local.emergencyContacts.length + 1
              }}</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- End Emergency Contacts -->

    <v-col cols="12">
      <v-divider />
    </v-col>

    <!-- Medical and Insurance -->
    <v-col cols="12">
      <h2 class="subtitle">Medical and Insurance</h2>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Medical Insurance</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Insurance Provider"
                v-model="local.medicalInsuranceProvider"
                @input="update('medicalInsuranceProvider', $event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name of Primary Insured"
                v-model="local.medicalInsuranceNameOfPrimaryInsured"
                @input="update('medicalInsuranceNameOfPrimaryInsured', $event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Policy Number"
                v-model="local.medicalInsurancePolicyNumber"
                @input="update('medicalInsurancePolicyNumber', $event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Group Number"
                v-model="local.medicalInsuranceGroupNumber"
                @input="update('medicalInsuranceGroupNumber', $event)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="6"
      v-for="(contact, i) in local.medicalProviders"
      :key="'medicalProvider' + i"
    >
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Medical Provider #{{ i + 1 }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            x-small
            fab
            v-if="i >= minMedicalProviders"
            @click="removeMedicalProvider(i)"
          >
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <MedicalProviderForm
            v-model="local.medicalProviders[i]"
            @input="update('medicalProviders', local.medicalProviders)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" height="100%">
      <v-card height="100%">
        <v-toolbar color="grey" dark>
          <v-toolbar-title>
            Medical Provider #{{
              local.medicalProviders.length + 1
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text color="grey">
          <v-row justify="center">
            <v-btn color="green darken-4" dark @click="addMedicalProvider()">
              Enter Additional Medical Provider
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- End Medical and Insurance -->

    <!-- Sliding Scale -->
    <v-col cols="12">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Sliding Scale</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
              <p>
                To make Village Free School affordable to all families, tuition
                is based on a percentage of family income. Please enter your
                family's annual gross income.
              </p>
              <p>
                If income has changed recently, or if income varies from month
                to month, enter the average total for the year for the past
                couple years.
              </p>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col cols="6" sm="4">
              <v-checkbox
                label="Opt Out"
                v-model="local.slidingScaleOptOut"
                @change="slidingScaleOptOutChanged()"
              />
            </v-col>
            <v-col cols="6" sm="4" v-show="!local.slidingScaleOptOut">
              <IncomeField
                ref="grossFamilyIncome"
                v-model="local.grossFamilyIncome"
                label="Gross Family Income"
              />
            </v-col>
            <v-col cols="6" sm="4" v-if="local.slidingScaleOptOut">
              Tuition calculation will use the full tuition rate.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- End Sliding Scale -->
  </v-row>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

import { cloneDeep, tap, set } from 'lodash';

import EmergencyContactForm from '@/components/EmergencyContactForm.vue';
import GuardianForm from '@/components/GuardianForm.vue';
import MedicalProviderForm from '@/components/MedicalProviderForm.vue';
import StudentForm from '@/components/StudentForm.vue';
import IncomeField from '@/components/IncomeField.vue';

export default {
  props: ['value'],
  mounted() {
    // this.$refs.familyName.focus();
  },
  data() {
    return {
      minStudents: 1,
      minGuardians: 1,
      minEmergencyContacts: 2,
      minMedicalProviders: 1,
      students: [],
    };
  },
  computed: {
    ...mapState(['user']),
    local() {
      const l = this.value
        ? this.value
        : {
            name: '',
            medicalInsuranceProvider: '',
            medicalInsuranceNameOfPrimaryInsured: '',
            medicalInsurancePolicyNumber: '',
            medicalInsuranceGroupNumber: '',
          };

      // Ensure students are populated
      l.students = l.students || [];
      while (l.students.length < this.minStudents) {
        l.students.push({ mediaRelease: true });
      }

      // Ensure guardians are populated
      l.guardians = l.guardians || [];
      while (l.guardians.length < this.minGuardians) {
        l.guardians.push({});
      }

      // Ensure emergencyContacts are populated
      l.emergencyContacts = l.emergencyContacts || [];
      while (l.emergencyContacts.length < this.minEmergencyContacts) {
        l.emergencyContacts.push({});
      }

      // Ensure medicalProviders are populated
      l.medicalProviders = l.medicalProviders || [];
      while (l.medicalProviders.length < this.minMedicalProviders) {
        l.medicalProviders.push({});
      }

      // Set authorizedEmails
      l.authorizedEmails = _.uniq(_.compact(_.map(l.guardians, 'email')));
      return l;
    },
  },
  methods: {
    addStudent() {
      const students = this.local.students || [];
      students.push({ mediaRelease: true });
      this.update('students', students);
    },
    removeStudent(i) {
      const students = this.local.students || [];
      students.splice(i, 1);
      this.update('students', students);
    },
    addEmergencyContact() {
      const contacts = this.local.emergencyContacts || [];
      contacts.push({});
      this.update('emergencyContacts', contacts);
    },
    removeEmergencyContact(i) {
      const contacts = this.local.emergencyContacts;
      contacts.splice(i, 1);
      this.update('emergencyContacts', contacts);
    },
    addGuardian() {
      const guardians = this.local.guardians || [];
      guardians.push({ atSameAddress: true });
      this.update('guardians', guardians);
    },
    removeGuardian(i) {
      const guardians = this.local.guardians;
      guardians.splice(i, 1);
      this.update('guardians', guardians);
    },
    addMedicalProvider() {
      const providers = this.local.medicalProviders || [];
      providers.push({});
      this.update('medicalProviders', providers);
    },
    removeMedicalProvider(i) {
      const providers = this.local.medicalProviders;
      providers.splice(i, 1);
      this.update('medicalProviders', providers);
    },
    canCopyGuardian(i) {
      return this.local.guardians[i] && this.local.guardians[i].firstName;
    },
    copyGuardianToContact(i) {
      if (this.local.guardians[i] && this.local.emergencyContacts[i]) {
        const src = this.local.guardians[i];
        const dst = _.pick(src, [
          'firstName',
          'lastName',
          'relationship',
          'cellPhone',
          'workPhone',
          'notes',
        ]);
        const contacts = this.local.emergencyContacts;
        contacts.splice(i, 1, dst);
        this.update('emergencyContacts', contacts);
      }
    },
    slidingScaleOptOutChanged() {
      if (this.local.slidingScaleOptOut) {
        this.update('grossFamilyIncome', null);
      } else {
        this.update('grossFamilyIncome', null);
      }
    },
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {
    EmergencyContactForm,
    GuardianForm,
    MedicalProviderForm,
    StudentForm,
    IncomeField,
  },
};
</script>

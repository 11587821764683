export const MaxYearOverYearChange = 0.1;
export const InflationIncrease = 250;
export const Steepness = 1.56;
export const SiblingDiscountFactor = 0.5;
export const PartTimeDiscountFactor = 0.625;

export const DefaultMinimumIncome = 28000;
export const DefaultMaximumIncome = 120000;

export const DefaultMinimumTuition = 1000;
export const DefaultMaximumTuition = 12500;

export const FullTime = 'Full Time';
export const PartTime = 'Part Time';
export const NotAttending = 'Not Attending';

// tuitionForIncome calculates the total tuition amount for the
// provided income. The opts can include:
// - year : A full year object with optional overrides
//           - minimumTuition
//           - maximumTuition
//           - minimumIncome
//           - maximumIncome
// - fullTime
// - partTime
// - siblings
// - inflationPeriods - The number of years worth of
//                      inflation increases to incorporate.
//
export function tuitionForIncome(income, opts = {}) {
  // Collect most basic algorithmic options
  const year = opts.year || {};
  const inflationPeriods = parseInt(opts.inflationPeriods || 0);
  const minTuition = parseFloat(year.minimumTuition || DefaultMinimumTuition);
  const maxTuition = parseFloat(year.maximumTuition || DefaultMaximumTuition);
  const minIncome = parseFloat(year.minimumIncome || DefaultMinimumIncome);
  const maxIncome = parseFloat(year.maximumIncome || DefaultMaximumIncome);
  const fullTimeCount = parseInt(opts.fullTime || 0);
  const partTimeCount = parseInt(opts.partTime || 0);
  const siblingCount = parseInt(opts.siblings || 0);

  // If income wasn't entered, assume the maximum
  if (income === null || income === undefined) {
    income = maxIncome;
  }

  // Calculate the base tuition
  let baseTuition = null;
  if (income <= minIncome) {
    baseTuition = minTuition;
    // Don't apply an inflation increase to minimum income
    // baseTuition += inflationPeriods * InflationIncrease;
  } else if (income >= maxIncome) {
    baseTuition = (maxTuition / maxIncome) * income;
    baseTuition += inflationPeriods * InflationIncrease;
  } else {
    const incomeRange = maxIncome - minIncome;
    const tuitionRange = maxTuition - minTuition;
    const x = (income - minIncome) / incomeRange;
    const y = exponentTransform(x, Steepness);
    baseTuition = minTuition + tuitionRange * y;
    baseTuition = baseTuition + inflationPeriods * InflationIncrease;
  }

  // Determine multiples for the number of full time, part time and siblings
  const fullTimeFactor = fullTimeCount;
  const partTimeFactor = partTimeCount * PartTimeDiscountFactor;
  const siblingFactor = siblingCount * SiblingDiscountFactor;
  return Math.round(
    baseTuition * (fullTimeFactor + partTimeFactor + siblingFactor)
  );
}

/**
 * minimumTuitionForIncome calculates the minimum acceptable tuition when the
 * income number is above the maximum income.
 */
export function minimumTuitionForIncome(income, opts = {}) {
  const year = opts.year || {};
  const maxIncome = parseFloat(year.maximumIncome || DefaultMaximumIncome);
  if (income <= maxIncome) {
    return tuitionForIncome(income, opts);
  } else {
    return tuitionForIncome(maxIncome, opts);
  }
}

// exponentTransform returns 0 > y > 1, such that steepness
// of 1 is a 45-degree linear line between 0,0 and 1,1, and
// higher steepnesses curve symmetrically towards the 1,0 corner
//
function exponentTransform(x, steepness) {
  const adjustedSteepness = steepness + 0.01; // Needed to avoid exactly equalling 1.
  const numerator = Math.pow(adjustedSteepness, x) - 1;
  const denominator = adjustedSteepness - 1;
  return numerator / denominator;
}

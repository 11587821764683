<template>
  <v-dialog v-model="isOpen" fullscreen scrollable>
    <v-form ref="form" @submit.prevent="save()">
      <v-card height="100%">
        <v-toolbar color="green darken-4" dark class="flex-grow-0">
          <v-toolbar-title>New Family</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <FamilyForm
            v-if="isOpen && family"
            v-model="family"
            @canceled="$emit('input', false)"
            @saved="$emit('saved', user)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FamilyForm from '@/components/FamilyForm.vue';

import { saveFamily } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      saving: false,
      isOpen: false,
      family: null,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      this.family = {
        name: '',
        guardians: [],
        emergencyContacts: [],
        medicalProviders: [],
      };
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const vm = this;
      vm.saving = true;
      saveFamily(vm.family)
        .then((docRef) => {
          vm.family.id = docRef.id;
          vm.$emit('saved', vm.family);
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    FamilyForm,
  },
};
</script>

<template>
  <v-row>
    <LabeledData cols="12" :label="guardian.relationship">
      {{ guardian.firstName }} {{ guardian.lastName }}
    </LabeledData>

    <LabeledData cols="12" sm="6" v-if="guardian.cellPhone" label="Cell Phone">
      {{ guardian.cellPhone }}
    </LabeledData>

    <LabeledData cols="12" sm="6" v-if="guardian.workPhone" label="Work Phone">
      {{ guardian.workPhone }}
    </LabeledData>

    <LabeledData cols="12" lg="6" label="Email">
      <a :href="'mailto:' + guardian.email">{{ guardian.email }}</a>
    </LabeledData>

    <LabeledData
      cols="12"
      lg="6"
      g-if="guardian.address || guardian.city"
      label="Address"
    >
      <div v-if="guardian.address1">{{ guardian.address1 }}</div>
      <div v-if="guardian.address2">{{ guardian.address2 }}</div>
      <div v-if="guardian.city">
        {{ guardian.city }}, {{ guardian.state }} {{ guardian.zip }}
      </div>
    </LabeledData>

    <LabeledData v-if="guardian.notes" cols="12" label="Notes">
      {{ guardian.notes }}
    </LabeledData>

    <LabeledData v-if="guardian.occupation" cols="12" sm="6" label="Occupation">
      {{ guardian.occupation }}
    </LabeledData>
  </v-row>
</template>

<script>
import LabeledData from '@/components/LabeledData.vue';

export default {
  props: ['guardian'],
  components: {
    LabeledData,
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="loading">
        <v-progress-linear indeterminate />
      </v-col>

      <v-col cols="12" v-if="!loading">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title> {{ year.name }} Roster </v-toolbar-title>

            <v-spacer />

            <v-text-field
              v-model="search"
              single-line
              hide-details
              label="Search"
            />
            <template v-slot:extension>
              <v-spacer />
              <v-btn small text @click="download()">
                <v-icon left small>fa-file-excel</v-icon>
                Download
              </v-btn>
            </template>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row justify="center" class="text-center">
                  <v-col cols="4" sm="3">
                    <div class="caption">Part Time</div>
                    <div class="display-1">{{ partTimeCount }}</div>
                  </v-col>
                  <v-col cols="4" sm="3">
                    <div class="caption">Full Time</div>
                    <div class="display-1">{{ fullTimeCount }}</div>
                  </v-col>
                  <v-col cols="4" sm="3">
                    <div class="caption">Total</div>
                    <div class="display-1">{{ enrolledStudents.length }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  dense
                  :items="enrolledStudents"
                  :headers="headers"
                  :search="search"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.studentName`]="{ item }">
                    <router-link
                      :to="{
                        name: 'familyShow',
                        params: { id: item.familyID },
                      }"
                    >
                      {{ item.studentName }}
                    </router-link>
                  </template>

                  <template v-slot:[`item.familyName`]="{ item }">
                    <router-link
                      :to="{
                        name: 'familyShow',
                        params: { id: item.familyID },
                      }"
                    >
                      {{ item.familyName }}
                    </router-link>
                  </template>

                  <template v-slot:[`item.birthdaySort`]="{ value, item }">
                    <span v-if="value">
                      {{ item.birthdayDisplay }}
                    </span>
                  </template>

                  <template v-slot:[`item.student.signSelfOut`]="{ value }">
                    <v-icon small v-if="value" color="green darken-2">
                      fa-file-signature
                    </v-icon>
                  </template>

                  <template v-slot:[`item.student.mediaRelease`]="{ value }">
                    <v-icon small v-if="value" color="green darken-2">
                      fa-camera-retro
                    </v-icon>
                    <v-icon small v-if="!value" color="red"> fa-ban </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';
import { db, enrolledStudentsInYear, contactToString } from '@/lib/firestoredb';

export default {
  props: ['id'],

  data() {
    return {
      loading: true,
      search: '',
      year: null,
      enrolledStudents: [],
      headers: [
        { text: 'Name', value: 'student.preferredName' },
        { text: 'Family', value: 'familyName' },
        { text: 'Enrollment', value: 'enrollmentType' },
        { text: 'Birthday', value: 'birthdaySort', align: 'right' },
        {
          text: 'Self Sign Out',
          value: 'student.signSelfOut',
          align: 'center',
        },
        {
          text: 'Media Release',
          value: 'student.mediaRelease',
          align: 'center',
        },
      ],
    };
  },

  computed: {
    partTimeCount() {
      return _.chain(this.enrolledStudents || [])
        .filter((e) => e.enrollmentType == 'Part Time')
        .value().length;
    },

    fullTimeCount() {
      return _.chain(this.enrolledStudents || [])
        .filter((e) => e.enrollmentType == 'Full Time')
        .value().length;
    },
  },

  watch: {
    id: {
      immediate: true,
      async handler(id) {
        this.loading = true;
        await Promise.all([this.bindYear(), this.fetchEnrolledStudents()]);
        this.loading = false;
      },
    },
  },

  methods: {
    /**
     * bindYear binds the local 'year' variable to the Firestore year
     * with the matching id. Vuefire automatically unbinds when the
     * component is destroyed, and re-binds when a new id is provided.
     */
    bindYear() {
      this.$bind('year', db.collection('years').doc(this.id));
    },

    /**
     * fetchEnrolledStudents gets the enrollments subcollection for the year,
     * and attaches a .family and .student ref based on the .studentID and
     * .familyID properties therein. It returns when all data is finished loading.
     */
    async fetchEnrolledStudents() {
      this.enrolledStudents = await enrolledStudentsInYear(this.id);
    },

    download() {
      const sheetName = this.year.name;
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: sheetName,
        Author: 'admin.villagefreeschool.org',
        CreatedDate: new Date(),
      };

      let data = [
        [
          'Name',
          'Family',
          'Enrollment',
          'Birthdate',
          'Pronoun',
          'Severe Allergies',
          'Non-Severe Allergies',
          'Other Medical Conditions',
          'Self Sign Out',
          'Media Release',
          'Emergency Contact #1',
          'Emergency Contact #2',
          'Emergency Contact #3',
        ],
      ];
      for (const e of this.enrolledStudents) {
        data.push([
          _.get(e, 'student.preferredName', ''),
          e.familyName,
          e.enrollmentType,
          e.student.birthdate,
          _.get(e, 'student.pronoun', ''),
          _.get(e, 'student.severeAllergies', ''),
          _.get(e, 'student.nonSevereAllergies', ''),
          _.get(e, 'student.otherMedicalConditions', ''),
          !!e.student?.signSelfOut ? 'YES' : '',
          !!e.student?.mediaRelease ? 'YES' : '',
          contactToString(_.get(e, 'family.emergencyContacts[0]')),
          contactToString(_.get(e, 'family.emergencyContacts[1]')),
          contactToString(_.get(e, 'family.emergencyContacts[2]')),
        ]);
      }
      let ws = XLSX.utils.aoa_to_sheet(data);

      // Fit columns to width
      ws['!cols'] = data[0].map((a, i) => ({ wch: Math.max(...data.map(a2 => a2[i] ? (_.min([40, a2[i].toString().length])) : 0)) })); // prettier-ignore
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const filename = `${this.year.name} - Roster - ${moment().format()}.xlsx`;
      XLSX.writeFile(wb, filename);
    },
  },
};
</script>

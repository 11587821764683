import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import moment from 'moment';

/**
 * projectName is the name of the Firebase Project that should be used.
 * This can be overridden in via a variable called VUE_APP_FIREBASE_PROJECT
 * as specified in a .env file as explained here:
 * https://cli.vuejs.org/guide/mode-and-env.html
 *
 * The .env.development file in this project will be used when running the
 * project in development mode (via `yarn run serve`).
 */
const projectName = process.env.VUE_APP_FIREBASE_PROJECT || 'vfsadmin';

/**
 * firebaseProjectConfigs holds the configuration supplied by the
 * Firebase Console after creating a new Firebase Project and setting
 * up the Web SDK to use it. This object is indexed by projectName,
 * with `vfsadmin` being the default so that it's used in product.
 */
const firebaseProjectConfigs = {
  'vfsadmin-dev': {
    apiKey: 'AIzaSyAAcO2NPCeYd5Gp4IpzMoI8zjavccf7lsk',
    authDomain: 'vfsadmin-dev.firebaseapp.com',
    projectId: 'vfsadmin-dev',
    storageBucket: 'vfsadmin-dev.appspot.com',
    messagingSenderId: '711628810105',
    appId: '1:711628810105:web:cc7dae01d97cbc9272b1a8',
    measurementId: 'G-X4Z5Y0KFWM',
  },
  vfsadmin: {
    apiKey: 'AIzaSyCVGq822-4eqNWdA2JiLJb6ute4i-2Q78k',
    authDomain: 'vfsadmin.firebaseapp.com',
    databaseURL: 'https://vfsadmin.firebaseio.com',
    projectId: 'vfsadmin',
    storageBucket: 'vfsadmin.appspot.com',
    messagingSenderId: '216985456646',
    appId: '1:216985456646:web:0f6cda01fd0bb02134b387',
    measurementId: 'G-X9QMPDKSGP',
  },
};

const config = firebaseProjectConfigs[projectName];

if (!config) {
  console.error(
    `No Firebase DB config for project '${projectName}'. ` +
      `Define a config in firestoredb.js`
  );
}

export const CHUNK_SIZE = 10;

// Get a Firestore instance
export const db = firebase.initializeApp(config).firestore();
export const userDB = db.collection('users');
export const familyDB = db.collection('families');
export const studentDB = db.collection('students');
export const yearDB = db.collection('years');
export const userFamilyDB = db.collection('userFamilies');

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };

/**
 * saveFamily is the central handler for saving a family, its guardians and
 * students. It updates the following FirestoreDB collections:
 * - families - The main family database
 * - students - An independent database of individual students
 * - userFamilies - THe fmamily lookup table for granting access to families
 *
 */
export async function saveFamily(family) {
  let familyID = family.id;
  if (!familyID) {
    familyID = familyDB.doc().id;
  }

  if (family.grossFamilyIncome < 1) {
    family.grossFamilyIncome = null;
  }

  try {
    // Assign missing student IDs
    for (let i = 0; i < family.students.length; i++) {
      if (!family.students[i].id) {
        family.students[i].id = studentDB.doc().id;
      }
    }

    await familyDB.doc(familyID).set(family);

    let emails = [];
    // Add Guardian email access
    emails.push(...family.guardians.map((g) => g.email));
    emails.push(...family.guardians.map((g) => _.split(g.otherEmails, ',')));
    // Add student email address
    emails.push(...family.students.map((s) => s.email));

    // Clean them up by flattening and trimming.
    emails = _.chain(emails)
      .flattenDeep()
      .map(_.trim)
      .map((s) => (s || '').toLowerCase())
      .compact()
      .value();

    // Save userFamily records
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      await userFamilyDB
        .doc(email)
        .set({
          familyID: familyID,
          familyName: family.name,
        })
        .catch(() => {
          console.log('Failed to set userFamilyRecord for ', email);
        });
    }

    // Save each student
    for (let i = 0; i < family.students.length; i++) {
      const student = family.students[i];
      let studentID = student.id;
      const studentData = Object.assign({}, student);
      studentData.familyID = familyID;
      await studentDB.doc(studentID).set(studentData);
    }

    // Save contracts
    // TODO

    // Save enrollments
    // TODO
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(family);
}

/**
 * deleteFamily deletes all records associated with a specific familyID. This
 * includes records in the following collections:
 * - families
 * - students
 * - userFamilies
 * - years/{year}/contracts
 * - years/{year}/enrollments
 */
export async function deleteFamily(family) {
  const familyID = family.id;

  // Delete student records
  const studentIDs = _.map(family.students || [], 'id');
  for (let i = 0; i < studentIDs.length; i++) {
    console.log('Deleting students/' + studentIDs[i]);
    await studentDB.doc(studentIDs[i]).delete();
  }

  const yearSnap = await yearDB.get();
  yearSnap.forEach((doc) => {
    const yearID = doc.id;
    console.log('Deleting years/' + yearID + '/contracts/' + familyID);
    yearDB.doc(yearID).collection('contracts').doc(familyID).delete();
    for (let i = 0; i < studentIDs.length; i++) {
      console.log('Deleting years/' + yearID + '/enrollments/' + studentIDs[i]);
      yearDB.doc(yearID).collection('enrollments').doc(studentIDs[i]).delete();
    }
  });

  // Finally delete the actual family
  console.log('Deleting families/' + familyID);
  await familyDB.doc(familyID).delete();
}

/**
 * enrolledStudentsInYear fetches a read-only array of "Enrolled Students".
 * Each result is based on the 'enrollments' collection underneath the year,
 * but it adds the full student and family objects instead of just references.
 * The finished objects look like this:
 *
 * {
 *    id: 'fdsfj',
 *    yearID: 'sdjfklfds',
 *    enrollmentType: 'Part Time',
 *    familyID: 'sadfjklsd',
 *    familyName: 'Rutland Family',
 *    studentID: 'sdfjklsd',
 *    studentName: 'Robert',
 *
 *    // Added Family object (fetched in batch from familyID)
 *    family: { ... },
 *
 *    // Added Student object (fetched in batch from studentID)
 *    student: { ... },
 *
 *    // Birthdate convenience fields
 *    birthday: '2005-05-10T00:00:00-07:00,
 *    birthdaySort: '05 10 May',
 *    birthdayDisplay: 'May 10th',
 * }
 *
 *
 *
 * @param yearID
 * @returns
 */
export async function enrolledStudentsInYear(yearID) {
  const enrolledStudents = [];
  const familyIDs = [];
  const studentIDs = [];
  const docs = await yearDB.doc(yearID).collection('enrollments').get();

  // First pass, we just collect the IDs of families and students we need
  docs.forEach((enrollment) => {
    const data = enrollment.data();
    familyIDs.push(data.familyID);
    studentIDs.push(data.studentID);
  });

  let results = await Promise.all([
    fetchFamiliesWithIDs(familyIDs),
    fetchStudentsWithIDs(studentIDs),
  ]);

  const families = _.keyBy(results[0], 'id');
  const students = _.keyBy(results[1], 'id');

  // Second pass, we add a .student and .family property
  docs.forEach((doc) => {
    const id = doc.id;
    const data = doc.data();
    const student = _.get(students, data.studentID);
    const birthday = student ?? student.birthdate ? student.birthdate : null;
    const birthdaySort = birthday
      ? moment(student.birthdate).format('MM DD MMMM')
      : '';
    const birthdayDisplay = birthday
      ? moment(student.birthdate).format('MMM Do')
      : '';

    enrolledStudents.push(
      Object.assign(
        {},
        {
          id,
          ...data,
          family: _.get(families, data.familyID),

          student,
          birthday,
          birthdaySort,
          birthdayDisplay,
        }
      )
    );
  });

  return enrolledStudents;
}

/**
 * enrolledFamiliesInYear fetches a read-only array of Families with at least
 * one student enrolled in the provided YearID. The returned records
 * SHOULD NOT BE EDITED and are not watched for server-side changes. This
 * function is to be used for reporting/listing only.
 *
 * @param yearID
 * @returns An array of Family objects.
 */
export async function enrolledFamiliesInYear(yearID) {
  const familyIDs = [];
  const validDecisions = ['Full Time', 'Part Time'];
  const docs = await yearDB.doc(yearID).collection('contracts').get();

  // First pass, we just collect the IDs of families attending
  docs.forEach((contract) => {
    const data = contract.data();
    const decisions = _.values(data.studentDecisions);
    if (_.intersection(decisions, validDecisions).length > 0) {
      familyIDs.push(contract.id); // Contract IDs are the ID of the family
    }
  });

  const results = await fetchFamiliesWithIDs(familyIDs);
  return results;
}

/**
 * fetchStudentsWithIDs is a utility function to get multiple *read-only*
 * Student collection entries from Firebase efficiently. Data returned from
 * this function should not be edited, and it will not live-update when the
 * individual Student records are changed.
 *
 * This function obeys Firebase's limit of 10 items being fetched by ID at
 * a time.
 *
 * @param ids An array of Student collection identifiers
 * @returns Student[]
 */
export async function fetchStudentsWithIDs(ids) {
  const idRef = firebase.firestore.FieldPath.documentId();
  const students = [];
  const chunks = _.chain([ids]).flatten().uniq().chunk(CHUNK_SIZE);
  for (const chunk of chunks) {
    const snap = await studentDB.where(idRef, 'in', chunk).get();
    snap.forEach((doc) => {
      students.push(Object.assign({ id: doc.id }, { ...doc.data() }));
    });
  }
  return students;
}

/**
 * fetchFamiliesWithIDs is a utility function to get multiple *read-only*
 * Family collection entries from Firebase efficiently. Data returned from
 * this function should not be edited, and it will not live-update when the
 * individual Student records are changed.
 *
 * This function obeys Firebase's limit of 10 items being fetched by ID at
 * a time.
 *
 * @param ids An array of Family collection identifiers
 * @returns Family[]
 */
export async function fetchFamiliesWithIDs(ids) {
  const idRef = firebase.firestore.FieldPath.documentId();
  const families = [];
  const chunks = _.chain([ids]).flatten().uniq().chunk(CHUNK_SIZE);
  for (const chunk of chunks) {
    const snap = await familyDB.where(idRef, 'in', chunk).get();
    snap.forEach((doc) => {
      families.push(Object.assign({ id: doc.id }, { ...doc.data() }));
    });
  }
  return families;
}

export function contactToString(contact) {
  if (!contact) {
    return '';
  }
  const firstName = _.get(contact, 'firstName');
  const cellPhone = _.get(contact, 'cellPhone');
  const rel = _.get(contact, 'relationship');
  let str = '';
  if (!firstName) {
    return str;
  }
  str += firstName;
  if (rel) {
    str += ` (${rel})`;
  }
  if (cellPhone) {
    str += ` ${cellPhone}`;
  }
  return str;
}

export function calculatedNameForFamily(family) {
  return (
    _.chain([...family.students, ...family.guardians])
      .map((s) => (s.lastName ? s.lastName : '').trim())
      .sort()
      .uniq()
      .value()
      .join(' / ') + ' Family'
  );
}

export function guardianNamesForFamily(family) {
  return _.chain(family.guardians)
    .map((g) => g.firstName)
    .join(', ')
    .value();
}

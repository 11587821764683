<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title>Users</v-toolbar-title>
            <template v-slot:extension>
              <v-btn
                fab
                absolute
                bottom
                left
                color="brown"
                dark
                @click="newUser()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-text>
            <v-data-table :items="users" :headers="headers">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      {{ item.email }}
                    </td>
                    <td>{{ item.firstName }} {{ item.lastName }}</td>
                    <td class="text-center">
                      <v-icon small v-if="item.isAdmin" color="green darken-4"
                        >fa-check</v-icon
                      >
                    </td>
                    <td class="text-center">
                      <v-icon small v-if="item.isStaff" color="green darken-4"
                        >fa-check</v-icon
                      >
                    </td>
                    <td>
                      <v-btn icon small @click="editUser(item.email)">
                        <v-icon small>fas fa-edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <UserNewDialog
      ref="newDialog"
      @saved="
        $set(userByID, $event.email, $event);
        userIDs.push($event.email);
      "
    />
    <UserEditDialog
      ref="editDialog"
      @saved="$set(userByID, $event.email, $event)"
    />
  </v-container>
</template>

<script lang="js">
import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

// import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

import UserNewDialog from '@/components/UserNewDialog.vue';
import UserEditDialog from '@/components/UserEditDialog.vue';

export default {
  created() {
    this.fetchData();
  },
  data() {
    return {
      userIDs: [],
      userByID: {},
      headers: [
        { value: 'email', text: 'Email' },
        { value: 'firstName', text: 'Name' },
        { value: 'isAdmin', align: 'center', text: 'Admin' },
        { value: 'isStaff', align: 'center', text: 'Staff' },
        { value: null, text: 'Edit', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    users() {
      return _.orderBy(this.userIDs.map((id) => this.userByID[id]), 'firstName');
    },
  },
  methods: {
    newUser() {
      this.$refs.newDialog.open();
    },
    editUser(email) {
      this.$refs.editDialog.open(email);
    },
    fetchData() {
      const vm = this;
      db.collection('users').get()
      .then(function(querySnapshot) {
        const ids = [];
        querySnapshot.forEach(function(doc) {
          const id = doc.id.toString();
          const user = doc.data();
          ids.push(id);
          user.email = id;
          Vue.set(vm.userByID, id, user);
        });
        vm.userIDs = ids;
      });
    },
  },
  components: {
    UserNewDialog,
    UserEditDialog,
  }
};
</script>

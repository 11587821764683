<template>
  <div>
    <ContractEditDialog ref="dialog" />

    <v-row v-if="loading">
      <v-col><v-progress-linear indeterminate /></v-col>
    </v-row>

    <v-row v-if="!loading && !contract">
      <v-col v-if="!contract" cols="12" class="text-center">
        Registration for this school year has not yet been submitted.
      </v-col>
    </v-row>

    <v-row
      v-if="!loading && contract && family && family.students"
      justify="space-around"
    >
      <LabeledData
        :cols="12 / family.students.length"
        :sm="8 / family.students.length"
        v-for="student in family.students"
        :key="student.id"
        :label="student.preferredName"
        class="text-center"
      >
        {{ contract ? contract.studentDecisions[student.id] : 'Unknown' }}
      </LabeledData>
      <LabeledData label="Total Tuition" class="text-center">
        {{ contract.tuition | currency }}
      </LabeledData>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col class="text-center">
        <v-btn v-if="canRegister" color="primary" @click="edit()">
          Register Now
        </v-btn>
        <v-btn v-if="canEditRegistration" color="primary" @click="edit()">
          Edit Registration
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="contract">
      <v-col class="text-center">
        <ContractPDFGenerator
          :year="year"
          :family="family"
          :contract="contract"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { familyDB, yearDB } from '@/lib/firestoredb';

import LabeledData from '@/components/LabeledData.vue';
import ContractEditDialog from '@/components/ContractEditDialog.vue';
import ContractPDFGenerator from '@/components/ContractPDFGenerator.vue';

export default {
  created() {
    this.loading = true;
    Promise.all([this.bindYear(), this.bindFamily(), this.bindContract()]).then(
      () => {
        this.loading = false;
      }
    );
  },
  props: {
    familyID: {
      type: String,
      required: true,
    },
    yearID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      family: null,
      year: null,
      contract: null,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin']),
    canRegister() {
      return (
        this.year &&
        (this.year.isAcceptingRegistrations || this.isAdmin) &&
        !this.contract
      );
    },
    canEditRegistration() {
      if (!this.contract) {
        // Can't edit if it doesn't exist. We create instead
        return false;
      }
      if (this.user && this.user.isAdmin) {
        // Always allow admin's to edit
        return true;
      }
      return (
        this.year &&
        this.year.isAcceptingRegistrations &&
        !this.contract.isSigned
      );
    },
  },
  watch: {
    familyID() {
      this.bindFamily();
      this.bindContract();
    },
    yearID() {
      this.bindYear();
      this.bindContract();
    },
  },
  methods: {
    bindFamily() {
      return this.$bind('family', familyDB.doc(this.familyID));
    },
    bindYear() {
      return this.$bind('year', yearDB.doc(this.yearID));
    },
    bindContract() {
      return this.$bind(
        'contract',
        yearDB.doc(this.yearID).collection('contracts').doc(this.familyID)
      );
    },
    edit() {
      this.$refs.dialog.open(this.familyID, this.yearID);
    },
  },
  components: {
    ContractEditDialog,
    ContractPDFGenerator,
    LabeledData,
  },
};
</script>

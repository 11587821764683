<template>
  <v-app>
    <v-app-bar app dark clipped-left color="brown">
      <v-app-bar-nav-icon v-if="showNav" @click.stop="toggleDrawer()" />
      <span class="title-1">Village Free School</span>
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          position="80% 50%"
          gradient="to top right, rgba(78, 74, 68,.5), rgba(78, 74, 68,.7)"
        ></v-img>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="showNav" v-model="drawerOpen" app clipped>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-if="user.isAdmin" class="title">
              VFS Admin Portal
            </v-list-item-title>
            <v-list-item-title v-if="!user.isAdmin" class="title">
              VFS Family Portal
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>fa-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'myFamily' }">
          <v-list-item-action>
            <v-icon>fa-user-friends</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>My Family</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="myFamily" :to="{ name: 'registration' }">
          <v-list-item-action>
            <v-icon>fa-file-contract</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Register</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.isAdmin" :to="{ name: 'familyList' }">
          <v-list-item-action>
            <v-icon>fa-user-friends</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Families</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <DirectoryPDFGenerator
          v-if="user.isAdmin && currentYear"
          :year="currentYear"
        />

        <v-list-item v-if="user.isAdmin" :to="{ name: 'yearList' }">
          <v-list-item-action>
            <v-icon>fa-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>School Years</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.isAdmin" :to="{ name: 'userList' }">
          <v-list-item-action>
            <v-icon>fa-user</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>User Accounts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--
        <v-list-item v-if="user.isAdmin" :to="{ name: 'revenueCalculator' }">
          <v-list-item-action>
            <v-icon>fa-money-check-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Revenue Calculator</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->

        <v-divider v-if="user.isAdmin" />

        <v-list-item @click="logOut()">
          <v-list-item-action>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-progress-linear indeterminate v-if="awaitingAuthInit" />
      <router-view v-if="!awaitingAuthInit"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import _ from 'lodash';
import firebase from 'firebase/app';

import { mapGetters, mapState } from 'vuex';
import { userDB, yearDB } from '@/lib/firestoredb';
import DirectoryPDFGenerator from '@/components/DirectoryPDFGenerator.vue';

export default {
  created() {
    this.initFirebaseAuth();
    this.$store.dispatch('bindYears');
  },

  data() {
    return {
      drawerOpen: null,
      years: [],
    };
  },

  computed: {
    ...mapState(['awaitingAuthInit', 'loggedIn', 'user', 'myFamily']),
    ...mapGetters(['isAdmin']),

    onLoginPage() {
      return this.$route.path === '/login';
    },

    onFamilyCalculator() {
      return this.$route.path === '/family-calculator';
    },

    showNav() {
      return this.user && this.user.email;
    },

    currentYear() {
      return _.find(this.years, { isAcceptingRegistrations: true });
    },
  },

  methods: {
    initFirebaseAuth() {
      this.$store.commit('startingAuth');
      firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          // Not signed in at all (even anonymously)
          this.$store.commit('setUserLoggedOut');
          firebase.auth().signInAnonymously();
          return;
        }

        if (!user.email) {
          // Logged in as an anonymous user with unknown email. Set the user
          this.$store.commit('setUser', user);
          return;
        }

        userDB
          .doc(user.email)
          .get()
          .then((doc) => {
            let userToStore = Object.assign({}, user);
            if (doc.exists) {
              userToStore = Object.assign(userToStore, doc.data());
            }
            this.$store.commit('setUser', userToStore);
            this.$store.dispatch('bindUserFamily', user.email);
          });
      });
    },

    toggleDrawer() {
      if (!this.drawerOpen) {
        this.drawerOpen = true;
      } else {
        this.drawerOpen = false;
      }
    },

    logOut() {
      const vm = this;
      vm.$store.commit('setUserLoggedOut');

      firebase
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          vm.$router.push({ name: 'login' });
        })
        .catch(function (error) {
          // An error happened.
          alert(error);
        });
    },
  },

  firestore: {
    years: yearDB.orderBy('name', 'desc'),
  },

  components: {
    DirectoryPDFGenerator,
  },
};
</script>

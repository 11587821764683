<template>
  <v-container>
    <v-row class="headline text-center">
      <v-col cols="12">
        VFS Tuition Calculator
        <v-btn x-small text @click="editingSettings = !editingSettings"
          >Edit Settings</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <div class="overline text-center">
          {{ minimumIncome | currency }}
        </div>
        <v-slider
          v-model="minimumIncome"
          min="1000"
          max="45000"
          step="1"
          label="Low Income"
          thumb-label
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="overline text-center">1 = Flat Slope</div>
        <v-slider
          v-model="steepness"
          min="1"
          max="8"
          step="0.01"
          label="Curve Sharpness"
          thumb-label
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-center overline">
          {{ maximumIncome | currency }}
        </div>
        <v-slider
          v-model="maximumIncome"
          min="90000"
          max="200000"
          step="5000"
          label="High Income"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="editingSettings" max-width="700">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model.number="minimumTuition"
                label="Minimum Tuition"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="maximumTuition"
                label="Maximum Tuition"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model.number="minimumIncome"
                label="Income for Minimum Tuition"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="maximumIncome"
                label="Income for Maximum Tuition"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="maxDelta"
                label="Max % Change for Existing Families"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="editingSettings = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th colspan="2" class="headline text-center">
                Full-Time Tuition
              </th>
              <th colspan="2" class="headline text-center">
                Part-Time Tuition
              </th>
              <th colspan="2" class="headline text-center">
                Sibling Full-Time
              </th>
              <th colspan="2" class="headline text-center">
                Sibling Part-Time
              </th>
            </tr>
            <tr>
              <th class="text-right">Gross Family Income</th>
              <th class="text-right">New Family</th>
              <th class="text-right blue--text">
                Existing Family
                <div>(max {{ maxDelta | percent }} change)</div>
              </th>
              <th class="text-right">New Family</th>
              <th class="text-right blue--text">Existing Family</th>
              <th class="text-right">New Family</th>
              <th class="text-right blue--text">Existing Family</th>
              <th class="text-right">New Family</th>
              <th class="text-right blue--text">Existing Family</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(income, i) in scenarios" :key="income">
              <td class="text-right subtitle-2">
                {{ income | currency }}
                <span v-if="i == 0">or less</span>
                <span v-if="i == scenarios.length - 1">or more</span>
              </td>
              <td class="text-right">
                {{ tuition(income) | currency }}
              </td>
              <td class="text-right blue--text">
                <div v-if="tuition(income) != adjustedTuition(income)">
                  {{ adjustedTuition(income) | currency }}
                </div>
              </td>
              <td class="text-right">
                {{ (tuition(income) * partTimeDiscountFactor) | currency }}
              </td>
              <td class="text-right blue--text">
                <div v-if="tuition(income) != adjustedTuition(income)">
                  {{
                    (adjustedTuition(income) * partTimeDiscountFactor)
                      | currency
                  }}
                </div>
              </td>
              <td class="text-right">
                {{ (tuition(income) * siblingDiscountFactor) | currency }}
              </td>
              <td class="text-right blue--text">
                <div v-if="tuition(income) != adjustedTuition(income)">
                  {{
                    (adjustedTuition(income) * siblingDiscountFactor) | currency
                  }}
                </div>
              </td>
              <td class="text-right">
                {{
                  (tuition(income) *
                    partTimeDiscountFactor *
                    siblingDiscountFactor)
                    | currency
                }}
              </td>
              <td class="text-right blue--text">
                <div v-if="tuition(income) != adjustedTuition(income)">
                  {{
                    (adjustedTuition(income) *
                      partTimeDiscountFactor *
                      siblingDiscountFactor)
                      | currency
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">

const STEPS = 15;

export default {
  data() {
    return {
      editingSettings: false,
      steepness: 1,
      partTimeDiscountFactor: 0.625,
      siblingDiscountFactor: 0.5,
      currentTuition: 8400,
      minimumTuition: 1000,
      maximumTuition: 12000,
      minimumIncome: 28000,
      maximumIncome: 115000,
      maxDelta: 0.1,
    };
  },
  computed: {
    scenarios() {
      const max = this.maximumIncome;
      const min = this.minimumIncome;
      const incomeStep = (max - min) / STEPS;
      const steps = [];
      for (let i = 0; i <= STEPS; i++) {
        steps.push(min + (i * incomeStep));
      }
      return steps;
    }
  },
  methods: {
    tuition(income) {
      if (income <= this.minimumIncome) {
        return this.minimumTuition;
      }
      if (income >= this.maximumIncome) {
        return this.maximumTuition;
      }

      // x is a value between 0 and 1 representing where the income
      // falls between max and min
      const x = (income - this.minimumIncome) / (this.maximumIncome - this.minimumIncome);

      // y is a value between 0 and 1 representing where the
      // tuition should fall between the max and min
      // const y = this.linearTransform(x);
      const y = this.exponentTransform(x);

      return this.minimumTuition + (this.maximumTuition - this.minimumTuition) * y;
    },
    linearTransform(x) {
      return x;
    },
    exponentTransform(x) {
      const adjustedSteepness = this.steepness + 0.01; // Needed to avoid exactly equalling 1.
      const numerator = Math.pow(adjustedSteepness, x) - 1;
      const denominator = adjustedSteepness - 1;
      return numerator / denominator;
    },
    adjustedTuition(income) {
      const actual = this.tuition(income);
      const max = this.currentTuition * (1+this.maxDelta);
      const min = this.currentTuition * (1-this.maxDelta);
      if (actual < min) {
        return min;
      }
      if (actual > max) {
        return max;
      }
      return actual;
    },
  },
};
</script>

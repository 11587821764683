<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <v-img src="/VFSLogo.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

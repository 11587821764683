<template>
  <v-dialog v-model="isOpen" max-width="800" scrollable>
    <v-form ref="form" v-if="year" @submit.prevent="save()">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Edit Year {{ year.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="loading">
          <v-progress-linear indeterminate />
        </v-card-text>
        <v-card-text v-if="!loading">
          <YearForm
            v-if="isOpen && year"
            v-model="year"
            @canceled="$emit('input', false)"
            @saved="$emit('saved', year)"
          />

          <YearTuitionChart :year="year" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import YearForm from '@/components/YearForm.vue';
import YearTuitionChart from '@/components/YearTuitionChart.vue';

import { db } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      isOpen: false,
      loading: false,
      saving: false,
      year: null,
    };
  },
  methods: {
    open(id) {
      const vm = this;
      vm.isOpen = true;
      vm.loading = true;
      db.collection('years')
        .doc(id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const year = doc.data();
            year.id = doc.id.toString();
            vm.year = year;
            vm.loading = false;
          } else {
            vm.isOpen = false;
            console.log('No such document: ', id);
          }
        })
        .catch(function (error) {
          vm.isOpen = false;
          console.log('Error getting document:', error);
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const vm = this;
      vm.saving = true;
      db.collection('years')
        .doc(vm.year.id)
        .set(vm.year)
        .then(() => {
          vm.$emit('saved', vm.year);
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    YearForm,
    YearTuitionChart,
  },
};
</script>

<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>Tuition Settings</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="6">
            <v-slider
              label="Steepness"
              v-model="steepness"
              min="1"
              max="8"
              step="0.01"
              thumb-label
            />
          </v-col>
          <v-col cols="6">
            <v-select
              label="Scaling Mode"
              :items="['percentage', 'direct']"
              v-model="scalingMode"
            />
          </v-col>
          <v-col cols="6">
            <v-slider
              label="Min Tuition"
              v-model="minimumTuition"
              min="250"
              step="250"
              max="5000"
            />
          </v-col>
          <v-col cols="6">
            <v-slider
              label="Max Tuition"
              v-model="maximumTuition"
              min="7000"
              step="250"
              max="36000"
            />
          </v-col>
          <v-col cols="6">
            <v-slider
              label="Min Income"
              v-model="minimumIncome"
              min="1000"
              step="1000"
              max="40000"
            />
            <v-row class="text-right">
              <v-col cols="12" sm="4">
                <div class="title">{{ minimumTuition | currency }}</div>
                <div class="overline">Min Tuition</div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="title">{{ minimumIncome | currency }}</div>
                <div class="overline">Income for Min Tuition</div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="title">
                  {{ minimumPercentageOfIncome | percent }}
                </div>
                <div class="overline">Tuition Share of Income</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-slider
              label="Max Income"
              v-model="maximumIncome"
              min="80000"
              step="1000"
              max="250000"
            />
            <v-row class="text-right">
              <v-col cols="12" sm="4">
                <div class="title">{{ maximumTuition | currency }}</div>
                <div class="overline">Max Tuition</div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="title">{{ maximumIncome | currency }}</div>
                <div class="overline">Income for Max Tuition</div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="title">
                  {{ maximumPercentageOfIncome | percent }}
                </div>
                <div class="overline">Tuition Share of Income</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="js">
import { mapGetters } from 'vuex';

export default {
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['minimumPercentageOfIncome', 'maximumPercentageOfIncome']),
    scalingMode: {
      get() { return this.$store.state.scalingMode; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'scalingMode', value }); },
    },
    minimumTuition: {
      get() { return this.$store.state.minimumTuition; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'minimumTuition', value }); },
    },
    maximumTuition: {
      get() { return this.$store.state.maximumTuition; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'maximumTuition', value }); },
    },
    minimumIncome: {
      get() { return this.$store.state.minimumIncome; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'minimumIncome', value }); },
    },
    maximumIncome: {
      get() { return this.$store.state.maximumIncome; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'maximumIncome', value }); },
    },
    steepness: {
      get() { return this.$store.state.steepness; },
      set(value) { this.$store.commit('setPropToValue', { prop: 'steepness', value })},
    }
  },
}
</script>

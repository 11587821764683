import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import CreateAccount from '@/views/CreateAccount.vue';

import Calculator from '@/views/Calculator.vue';
import FamilyCalculator from '@/views/FamilyCalculator.vue';
import FamilyList from '@/views/FamilyList.vue';
import FamilyShow from '@/views/FamilyShow.vue';
import Home from '@/views/Home.vue';
import Registration from '@/views/Registration.vue';
import RevenueCalculator from '@/views/RevenueCalculator.vue';
import SurveyList from '@/views/SurveyList.vue';
import UserList from '@/views/UserList.vue';
import YearContracts from '@/views/YearContracts.vue';
import YearList from '@/views/YearList.vue';
import YearRoster from '@/views/YearRoster.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: { allowAnonymous: true },
  },
  {
    path: '/create-account',
    name: 'createAccount',
    component: CreateAccount,
  },

  {
    path: '/calculator',
    name: 'calculator',
    component: Calculator,
  },
  {
    path: '/my-family',
    name: 'myFamily',
    component: FamilyShow,
  },
  {
    path: '/families',
    name: 'familyList',
    component: FamilyList,
  },
  {
    path: '/families/:id',
    name: 'familyShow',
    component: FamilyShow,
    props: true,
  },
  {
    path: '/families/:id/register',
    name: 'familyRegistration',
    component: Registration,
    props: true,
  },
  {
    path: '/family-calculator',
    name: 'familyCalculator',
    component: FamilyCalculator,
    meta: { allowAnonymous: true },
  },
  {
    path: '/register',
    name: 'registration',
    component: Registration,
    props: true,
  },
  {
    path: '/revenue-calculator',
    name: 'revenueCalculator',
    component: RevenueCalculator,
  },
  {
    path: '/surveys',
    name: 'surveyList',
    component: SurveyList,
  },
  {
    path: '/users',
    name: 'userList',
    component: UserList,
    meta: { adminOnly: true },
  },
  {
    path: '/years',
    name: 'yearList',
    component: YearList,
  },
  {
    path: '/years/:id/roster',
    name: 'yearRoster',
    component: YearRoster,
    props: true,
  },
  {
    path: '/years/:id/contracts',
    name: 'yearContracts',
    component: YearContracts,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('awaitAuth').then(() => {
    const loggedIn = !!store.state.loggedIn;

    // Login Page and Create Account Pages
    if (to.path === '/login' || to.path === '/create-account') {
      if (!loggedIn) {
        next();
      } else {
        // Already logged in.. just go home.
        next('/my-family');
      }
      return;
    }

    // Admin Routes
    if (to.matched.some((r) => r.meta.adminOnly)) {
      if (store.state.user.isAdmin) {
        next();
      } else {
        // TODO Find a better place to send access denied.
        next('/');
      }
      return;
    }

    // Authenticated Pages for logged-in users, or
    // Anonymous-accessible pages
    if (loggedIn || to.meta.allowAnonymous) {
      next();
      return;
    }

    // Anonymous users trying to access a regular
    // page.
    next('/login');
  });
});

export default router;

<template>
  <div>
    <v-row>
      <v-col cols="12" class="display-1">
        {{ student.preferredName }}
        <span class="subtitle-1" v-if="student.pronoun"
          >( {{ student.pronoun }} )</span
        >
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <LabeledData label="First">
        {{ student.firstName }}
      </LabeledData>
      <LabeledData label="Middle">
        {{ student.middleName }}
      </LabeledData>
      <LabeledData label="Last">
        {{ student.lastName }}
      </LabeledData>
    </v-row>
    <v-row justify="space-around">
      <LabeledData cols="12" label="Email Address" v-if="student.emailAddress">
        {{ student.emailAddress }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        error
        v-if="student.severeAllergies"
        label="Severe Allergies"
      >
        {{ student.severeAllergies }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        v-if="student.nonSevereAllergies"
        label="Non-Severe Allergies"
      >
        {{ student.nonSevereAllergies }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        label="Prior School"
        v-if="student.priorSchool"
      >
        {{ student.priorSchool }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        label="Learning Disabilities"
        v-if="student.learningDisabilities"
      >
        {{ student.learningDisabilities }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        label="Additional Information"
        v-if="student.additionalInfo"
      >
        {{ student.additionalInfo }}
      </LabeledData>
      <LabeledData
        cols="12"
        md="6"
        label="Other Medical Conditions"
        v-if="student.otherMedicalConditions"
      >
        {{ student.otherMedicalConditions }}
      </LabeledData>
    </v-row>
  </div>
</template>

<script>
import LabeledData from '@/components/LabeledData.vue';

export default {
  props: ['student'],
  components: {
    LabeledData,
  },
};
</script>

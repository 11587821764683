<template>
  <v-select
    :label="label"
    :rules="rules"
    :value="value"
    :items="relationshipOptions"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: 'Relationship',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      relationshipOptions: [
        'Father',
        'Mother',
        'Parent',
        'Grandparent',
        'Stepfather',
        'Stepmother',
        'Brother',
        'Sister',
        'Legal Guardian',
        'Uncle',
        'Aunt',
        'Step-grandparent',
        'Friend',
        'Neighbor',
        'Housemate',
        'Other',
      ],
    };
  },
};
</script>

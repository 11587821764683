<template>
  <v-container>
    <TuitionSettingsWidget v-if="isAdmin" />
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1"> Attending Students </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2"> New or Returning Family </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3"> Income </v-stepper-step>
        <v-stepper-step step="4"> Rates </v-stepper-step>
        <v-stepper-step step="5"> Schedule </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!--

          Step One: Kids

        -->
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="8" md="4">
                  <v-select
                    label="Number of Children"
                    v-model="childCount"
                    :items="[1, 2, 3, 4, 5, 6]"
                  />
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col
                  v-for="(child, i) in children"
                  :key="i"
                  :md="children.length > 1 ? 12 / children.length : 4"
                >
                  <v-select
                    :label="'Child #' + (i + 1)"
                    v-model="child.isPartTime"
                    :items="partTimeChoices"
                    item-text="name"
                    item-value="isPartTime"
                    @change="resetCurrentTuition()"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()"
                >Back</v-btn
              >
              <v-btn color="primary" v-if="canGoForward" @click="next()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step Two
          New/Returning and Old Tuition

        -->
        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <v-row justify="space-around">
                <v-col cols="12" sm="6" md="4">
                  <v-radio-group v-model="returningFamily" column>
                    <v-radio label="Returning Family" :value="true"></v-radio>
                    <v-radio label="New Family" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-if="returningFamily" cols="12" sm="6" md="4">
                  <v-currency-field
                    v-model="priorTuition"
                    prefix="$"
                    label="Current Total Family Tuition"
                    hint="Change this if your family received tuition assistance this year."
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()"
                >Back</v-btn
              >
              <v-btn color="primary" v-if="canGoForward" @click="next()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step Three
          Income

        -->
        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <p>
                    To make Village Free School affordable to all families,
                    tuition is based on a percentage of gross family income.
                  </p>
                  <p>
                    Please enter your family's annual gross income. If income
                    has changed recently, enter the average of the past 2 years.
                  </p>
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col cols="12" md="4">
                  <v-currency-field
                    v-model="income"
                    label="Gross Family Income"
                    prefix="$"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()"
                >Back</v-btn
              >
              <v-btn color="primary" v-if="canGoForward" @click="next()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step Four
          Tuition Amounts

        -->
        <v-stepper-content step="4">
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" md="4" class="text-center">
                  <div class="display-1">{{ income | currency }}</div>
                  <div class="overline">Gross Family Income</div>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-row justify="center">
                      <v-col cols="12" class="subtitle-1 text-center">
                        Advertised Tuition Rates
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="10">
                        <p class="caption" v-if="returningFamily">
                          <strong>Returning Families:</strong>
                          These are <strong>NOT</strong> the rates your family
                          will be asked to pay next year. Please continue to the
                          next page to see your adjusted total, and a transition
                          schedule customized for your family.
                        </p>
                        <p class="caption" v-if="!returningFamily">
                          These are the base rates for your declared income.
                          Please continue to the next page for a forecast of
                          your tuition expenses.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="6" sm="4" class="text-center">
                        <div class="display-1">
                          {{ fullTimeTuition | currency }}
                        </div>
                        <div class="overline">
                          Full Time Tuition<br />
                          ( {{ (fullTimeTuition / income) | percent }} of Income
                          )
                        </div>
                      </v-col>
                      <v-col cols="6" sm="4" class="text-center">
                        <div class="display-1">
                          {{ partTimeTuition | currency }}
                        </div>
                        <div class="overline">Part Time Tuition</div>
                      </v-col>
                      <v-col cols="6" sm="4" class="text-center">
                        <div class="display-1">
                          {{ siblingTuition | currency }}
                        </div>
                        <div class="overline">Sibling Tuition</div>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="10" sm="5" md="6">
                        <p>Do these rates seem reasonable?</p>
                        <v-radio-group
                          v-model="marketRateFeedback"
                          @change="saveSurvey()"
                          column
                        >
                          <v-radio
                            label="No, Too High"
                            value="tooHigh"
                          ></v-radio>
                          <v-radio
                            label="Yes, They're About Right"
                            value="justRight"
                          ></v-radio>
                          <v-radio label="No, Too Low" value="tooLow"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()">
                Back
              </v-btn>
              <v-btn color="primary" v-if="canGoForward" @click="next()">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step 5
          Year-by-Year Schedule

        -->
        <v-stepper-content step="5">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="display-1">Your Family's Tuition Plan</v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" sm="8" md="4" class="body-2 text-center">
                  <p>
                    Rate: {{ fullTimeCount }} full time,
                    {{ siblingCount }} sibling, {{ partTimeCount }} part time.
                  </p>
                </v-col>
              </v-row>

              <v-row justify="center" v-if="years.length">
                <v-col cols="6" sm="4" class="text-center">
                  <div class="display-1">
                    {{ nextYearAdjustedTuition | currency }}
                  </div>
                  <div class="overline">
                    Total Tuition<br />
                    Next Year
                  </div>
                </v-col>
                <v-col cols="6" sm="4" class="text-center">
                  <div class="display-1">
                    {{ (nextYearAdjustedTuition / 10) | currency }}
                  </div>
                  <div class="overline">10 Monthly Payments</div>
                </v-col>
                <v-col cols="6" sm="4" class="text-center">
                  <div class="display-1">
                    {{ (nextYearAdjustedTuition / 12) | currency }}
                  </div>
                  <div class="overline">12 Monthly Payments</div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="8" class="text-center caption">
                  Our top tuition rate increases with inflation each year.<br />
                  Estimates below show expected changes over the next
                  {{ yearsToForecast }} years.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="forecastHeaders"
                    :items="years"
                    :mobile-breakpoint="200"
                    hide-default-footer
                    disable-sort
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-right">
                          {{ item.year }}
                        </td>
                        <td class="text-right">
                          {{ item.trueTuition | currency }}
                        </td>
                        <td class="text-right" v-if="returningFamily">
                          <div v-if="item.adjustedTuition != item.trueTuition">
                            {{ item.adjustedTuition | currency }}
                          </div>
                          <div v-if="item.adjustedTuition == item.trueTuition">
                            N/A
                          </div>
                        </td>
                        <td class="text-right" v-if="returningFamily">
                          <div v-if="item.yoyDollarChange != 0">
                            {{ item.yoyDollarChange | currency }}
                          </div>
                          <div v-if="item.yoyPercentChange != 0">
                            <span>(</span>
                            <span> {{ item.yoyPercentChange | percent }} </span>
                            <span>)</span>
                          </div>
                        </td>
                        <td class="text-right">
                          {{ (item.adjustedTuition / 10) | currency }}
                        </td>
                        <td class="text-right">
                          {{ (item.adjustedTuition / 12) | currency }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12" sm="10" md="8">
                  <p>Will this plan work for your family?</p>
                  <v-radio-group
                    v-model="paymentScheduleAcceptable"
                    @change="saveSurvey()"
                    column
                  >
                    <v-radio label="Yes, this will work." value="yes"></v-radio>
                    <v-radio
                      v-if="goingUpWithTransition"
                      label="Yes, but we can transition to a higher rate faster."
                      value="yesPlus"
                    ></v-radio>
                    <v-radio
                      label="No, we will need further tuition assistance."
                      value="no"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()">
                Back
              </v-btn>
              <v-btn color="primary" v-if="canGoForward" @click="next()">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step 6
          Intent to Return Survey 

        -->
        <v-stepper-content step="6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="display-1">Intent to Return</v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="10" md="8">
                  <p>Is your family planning to return next year?</p>
                  <v-radio-group v-model="planningToReturn" column>
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio
                      label="Maybe, we're still deciding"
                      value="maybe"
                    ></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="10" md="8">
                  <v-text-field
                    label="Family Name"
                    v-model="familyName"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="10" md="8">
                  <v-text-field
                    label="Email Address"
                    v-model="emailAddress"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="10" md="8">
                  <v-textarea
                    outlined
                    label="Any feedback on the new VFS Sliding Scale?"
                    v-model="feedback"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()">
                Back
              </v-btn>
              <v-btn
                color="accent"
                v-if="canGoForward"
                @click="sendIntentToReturn()"
              >
                <v-icon small left>fa-paper-plane</v-icon>
                Send
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!--

          Step 7

        -->
        <v-stepper-content step="7">
          <v-card>
            <v-row justify="center">
              <v-col cols="12" sm="6" md="4" class="display-2">
                Thank you!
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" sm="6" md="4">
                <p>
                  We appreciate you taking the time to review our new sliding
                  scale tuition plan.
                </p>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" v-if="canGoBack" text @click="back()">
                Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script lang="js">
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

import TuitionSettingsWidget from '@/components/TuitionSettingsWidget.vue';

export default {
  data() {
    return {
      step: 1,
      yearsToForecast: 5,
      returningFamily: null,
      childCount: 1,
      income: null,
      priorTuition: 8400,
      children: [{ isPartTime: false, isSibling: false }],
      partTimeChoices: [
        { isPartTime: false, name: 'Full-Time' },
        { isPartTime: true, name: 'Part-Time' },
      ],
      // Survey Questions
      marketRateFeedback: '',
      paymentScheduleAcceptable: '',
      planningToReturn: '',
      emailAddress: '',
      familyName: '',
      feedback: '',
    };
  },
  computed: {
    ...mapState([
      'allowSiblingDiscountForPartTime',
      'inflationIncrease',
      'legacyBaseTuition',
      'maximumIncome',
      'maxYearOverYearChange',
      'minimumIncome',
      'partTimeDiscountFactor',
      'siblingDiscountFactor',
      'user',
    ]),
    ...mapGetters(['baseTuitionForIncome', 'partTimeTuitionForIncome', 'siblingTuitionForIncome', 'isAdmin']),
    forecastHeaders() {
      if (this.returningFamily) {
        return [
        { text: 'Year', value: 'year', align: 'end' },
        { text: 'Total Advertised Tuition', value: 'trueTuition', align: 'end' },
        { text: 'Total Adjusted Tuition', value: 'adjustedTuition', align: 'end' },
        { text: 'Change from Prior Year', value: 'yoyDollarChange', align: 'end' },
        { text: '10 Monthly Payments of', value: 'adjustedTuition', align: 'end' },
        { text: '12 Monthly Payments of', value: 'adjustedTuition', align: 'end' },
        ];
      } else {
        return [
        { text: 'Year', value: 'year', align: 'end' },
        { text: 'Total Tuition', value: 'trueTuition', align: 'end' },
        { text: '10 Monthly Payments of', value: 'trueTuition', align: 'end' },
        { text: '12 Monthly Payments of', value: 'trueTuition', align: 'end' },
        ];
      }
    },
    goingUp() {
      return this.nextYearTuition > this.priorTuition;
    },
    goingUpWithTransition() {
      return this.goingUp && this.nextYearTuition > this.nextYearAdjustedTuition;
    },
    goingDown() {
      return this.nextYearTuition < this.priorTuition;
    },
    goingDownWithTransition() {
      return this.goingDown && this.nextYearTuition < this.nextYearAdjustedTuition;
    },
    nextYearTuition() {
      return this.baseTuitionForIncome(this.income, 0) * this.baseTuitionMultipleForFamily;
    },
    nextYearAdjustedTuition() {
      if (this.returningFamily) {
        return this.adjust(this.nextYearTuition, this.priorTuition);
      } else {
        return this.nextYearTuition;
      }
    },
    fullTimeTuition() {
      return this.baseTuitionForIncome(this.income, 0);
    },
    partTimeTuition() {
      return this.partTimeTuitionForIncome(this.income, 0);
    },
    siblingTuition() {
      return this.siblingTuitionForIncome(this.income, 0);
    },
    fullTimeCount() {
      return _.filter(this.children, (c) => !c.isPartTime && !c.isSibling).length;
    },
    partTimeCount() {
      return _.filter(this.children, (c) => c.isPartTime).length;
    },
    siblingCount() {
      return _.filter(this.children, (c) => c.isSibling).length;
    },
    /**
     * baseTuitionMultipleForFamily returns the number to multiply by the base
     * tuition to get this family's total tuition bill. For a family with a
     * single full-time student, this returns 1. For a family with 2 full-time
     * students, it returns 1.5. For a family with 2 part-time students, it
     * returns 1.25
     */
    baseTuitionMultipleForFamily() {
      var multiple = 0;
      for (const child of this.children) {
        if (child.isPartTime) {
          if (child.isSibling && this.allowSiblingDiscountForPartTime) {
            multiple += (this.partTimeDiscountFactor * this.siblingDiscountFactor)
          } else {
            multiple += (this.partTimeDiscountFactor)
          }
        } else { // Full-time
          if (child.isSibling) {
            multiple += this.siblingDiscountFactor
          } else {
            multiple += 1.0
          }
        }
      }
      return multiple;
    },
    years() {
      const results = [];
      var priorTotal = this.priorTuition;
      for(var i = 0; i < this.yearsToForecast; i++) {
        const myBase = this.baseTuitionForIncome(this.income, i);
        const trueTuition = myBase * this.baseTuitionMultipleForFamily;
        var adjustedTuition = trueTuition;
        if (this.returningFamily) {
          adjustedTuition = this.adjust(trueTuition, priorTotal);
        }
        results.push({
          year: i + 1,
          trueTuition: trueTuition,
          percentageOfIncome: trueTuition / this.income,
          adjustedTuition: adjustedTuition,
          yoyPercentChange: (adjustedTuition / priorTotal) - 1,
          yoyDollarChange: (adjustedTuition - priorTotal),
        });
        priorTotal = adjustedTuition;
      }
      return results;
    },
    canGoBack() {
      // Don't allow going back after entering
      // income numbers
      return this.step != 4;
    },
    canGoForward() {
      // Require entering an income before proceeding
      // forward
      if (this.step == 3 && this.income < 1) {
        return false;
      }
      // Require choosing either new or returning family
      if (this.step == 2 && this.returningFamily === null) {
        return false;
      }
      return this.step <= 6;
    }
  },
  watch: {
    childCount() {
      const children = [];
      for (var i = 0; i < this.childCount; i++) {
        children.push({ isPartTime: false, isSibling: (i > 0) });
      }
      this.children = children;
      this.resetCurrentTuition();
    },
  },
  methods: {
    next() {
      if (this.step < 6) {
        this.step++;
      }
    },
    back() {
      if (this.step > 1) {
        this.step--;
      }
    },
    resetCurrentTuition() {
      this.priorTuition = this.legacyBaseTuition * this.baseTuitionMultipleForFamily;
    },
    adjust(advertisedVal, legacyVal) {
      if (advertisedVal > legacyVal * (1+this.maxYearOverYearChange)) {
        return legacyVal * (1+this.maxYearOverYearChange);
      } else if (advertisedVal < legacyVal * (1-this.maxYearOverYearChange)) {
        return legacyVal * (1-this.maxYearOverYearChange);
      } else {
        return advertisedVal;
      }
    },
    saveSurvey() {
      const uid = (this.user) ? this.user.uid : 'anonymous';
      db.collection('advertisedRateSurveys').doc(uid).set({
        uid: uid,
        childCount: this.childCount,
        fullTimeCount: this.fullTimeCount,
        partTimeCount: this.partTimeCount,
        siblingCount: this.siblingCount,
        income: this.income,

        goingUp: this.goingUp,
        priorTuition: this.priorTuition,
        nextYearTuition: this.nextYearTuition,
        nextYearAdjustedTuition: this.nextYearAdjustedTuition,
        marketRateFeedback: this.marketRateFeedback,
        paymentScheduleAcceptable: this.paymentScheduleAcceptable,
        fullTimeTuition: this.fullTimeTuition,
        partTimeTuition: this.partTimeTuition,
        siblingTuition: this.siblingTuition,
        receivedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },
    sendIntentToReturn() {
      const uid = (this.user) ? this.user.uid : 'anonymous';
      db.collection('intentToReturns').doc(uid).set({
        uid: uid,
        childCount: this.childCount,
        fullTimeCount: this.fullTimeCount,
        partTimeCount: this.partTimeCount,
        siblingCount: this.siblingCount,
        income: this.income,

        goingUp: this.goingUp,

        priorTuition: this.priorTuition,
        nextYearTuition: this.nextYearTuition,
        nextYearAdjustedTuition: this.nextYearAdjustedTuition,

        marketRateFeedback: this.marketRateFeedback,
        paymentScheduleAcceptable: this.paymentScheduleAcceptable,

        planningToReturn: this.planningToReturn,
        emailAddress: this.emailAddress,
        familyName: this.familyName,
        feedback: this.feedback,

        receivedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(() => {
        this.step++;
      });
    },
  },
  components: {
    TuitionSettingsWidget,
  }
}
</script>

<template>
  <div>
    <v-row justify="space-around" dense>
      <v-col cols="12" sm="4">
        <v-text-field
          label="First Name"
          v-model="local.firstName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('firstName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Last Name"
          v-model="local.lastName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('lastName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <RelationshipDropdown
          v-model="local.relationship"
          :rules="[(v) => !!v || 'Required']"
          @input="update('relationship', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Cell Phone"
          v-model="local.cellPhone"
          v-mask="'###-###-####'"
          :masked="true"
          :rules="[(v) => !!v || 'Required']"
          @input="update('cellPhone', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Work Phone"
          v-model="local.workPhone"
          v-mask="'###-###-####'"
          @input="update('workPhone', $event)"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          label="Notes"
          v-model="local.notes"
          rows="2"
          @input="update('notes', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';

import RelationshipDropdown from '@/components/RelationshipDropdown.vue';

export default {
  props: ['value'],
  data() {
    return {
      relationshipOptions: [
        'Father',
        'Mother',
        'Parent',
        'Grandparent',
        'Legal Guardian',
        'Stepfather',
        'Stepmother',
        'Step-parent',
        'Step-grandparent',
        'Uncle',
        'Aunt',
        'Other',
      ],
    };
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            firstName: '',
            lastName: '',
            relationship: null,
            cellPhone: '',
            workPhone: '',
            notes: '',
          };
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {
    RelationshipDropdown,
  },
};
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-row justify="center" justify-content="center">
          <v-col cols="12" sm="10" md="4">
            <v-spacer />
          </v-col>
        </v-row>

        <v-form ref="form" @submit.prevent="submitLogin">
          <v-card>
            <v-toolbar color="green darken-4" dark>
              <v-toolbar-title>VFS Portal Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row justify="space-around">
                <v-col cols="5" class="pt-6">
                  <p>
                    Have a Google Account? Register and login with one click:
                  </p>
                </v-col>
                <v-col cols="5">
                  <v-img
                    max-width="200"
                    @click.prevent="googleLogin()"
                    src="/btn_google_signin_dark_normal_web@2x.png"
                    style="cursor: pointer"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-alert type="error" v-if="errorMessage">
                {{ errorMessage }}
              </v-alert>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Email Address"
                    v-model="email"
                    :rules="[(v) => !!v || 'Required']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password"
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                x-small
                color="error"
                text
                :to="{ name: 'forgotPassword' }"
              >
                Forgot Password?
              </v-btn>

              <v-btn
                color="green darken-4"
                dark
                :loading="loggingIn"
                type="submit"
              >
                <v-icon left>fa-sign-in-alt</v-icon>
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-row>
          <v-col>
            If you haven't created an account on the VFS Portal yet, and you
            don't have a Google Account, then you'll need to create one:

            <v-btn
              small
              color="green darken-4"
              dark
              :to="{ name: 'createAccount' }"
            >
              Create an Account
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="errorCode">
      <v-col class="text-center red--text caption">
        <pre>{{ errorCode }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { userDB } from '@/lib/firestoredb';
import router from '@/router';

export default {
  mounted() {},
  data() {
    return {
      loggingIn: false,
      email: '',
      password: '',
      errorCode: '',
      errorMessage: '',
    };
  },
  computed: {
    passwordRules() {
      return [
        (v) => !!v || 'Required',
        (v) =>
          (v || '').length >= 6 || 'Passwords must be at least 6 characters',
      ];
    },
  },
  methods: {
    googleLogin() {
      const vm = this;
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(googleProvider)
        .then((result) => {
          // const token = result.credential.accessToken;
          const user = result.user;
          vm.setUser(user);
        })
        .catch((error) => {
          vm.errorCode = error.code;
          vm.errorMessage = error.message;
          // vm.email = error.email;
          // vm.credential = error.credential;
        });
    },
    submitLogin() {
      const vm = this;
      vm.loggingIn = true;
      vm.errorCode = '';
      vm.errorMessage = '';

      if (!this.$refs.form.validate()) {
        return;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          // const token = result.credential.accessToken;
          const user = result.user;
          vm.setUser(user);
        })
        .catch((error) => {
          vm.errorCode = error.code;
          vm.errorMessage = error.message;
        })
        .finally(() => {
          vm.loggingIn = false;
        });
    },
    setUser(user) {
      if (user) {
        if (user.email) {
          this.$store.dispatch('bindUserFamily', user.email.toLowerCase());
          userDB
            .doc(user.email)
            .get()
            .then((doc) => {
              let userToStore = Object.assign({}, user);
              if (doc.exists) {
                userToStore = Object.assign(userToStore, doc.data());
              }
              this.$store.commit('setUser', userToStore);
              router.push({ name: 'myFamily' }).catch((err) => {
                alert(err);
              });
            })
            .catch((err) => {
              console.log(
                'Error fetching /users/' + user.email.toLowerCase(),
                err
              );
            });
        } else {
          // User is not authenticated, but we still need to
          // update the store with their anonymous UID
          this.$store.commit('setUser', user);
        }
      }
    },
  },
};
</script>

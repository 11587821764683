<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-row justify="center" justify-content="center">
          <v-col cols="12" sm="10" md="4">
            <v-spacer />
          </v-col>
        </v-row>

        <v-form ref="form" @submit.prevent="send" v-if="!sent">
          <v-card>
            <v-toolbar color="green darken-4" dark>
              <v-toolbar-title>Forgot Password? </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="errorMessage">
                {{ errorMessage }}
              </v-alert>
              <v-row>
                <v-col cols="12">
                  This page allows you to reset your account password on the VFS
                  Portal.
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email Address"
                    v-model="email"
                    :rules="[(v) => !!v || 'Required']"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn x-small text color="error" :to="{ name: 'login' }">
                <v-icon left x-small>fa-arrow-left</v-icon>
                Back to Login
              </v-btn>
              <v-spacer />
              <v-btn
                color="green darken-4"
                dark
                :loading="sending"
                type="submit"
              >
                <v-icon left small>fa-paper-plane</v-icon>
                Send Password Reset Email
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-row v-if="!sent">
          <v-col cols="12" class="pa-8 text-caption">
            If you haven't created an account on the VFS Portal yet, and you
            don't have a Google Account, then you'll need to create one:

            <v-btn
              x-small
              color="green darken-4"
              dark
              :to="{ name: 'createAccount' }"
            >
              Create an Account
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="sent">
          <v-col cols="12">
            <p>
              Password reset instructions have been sent to your email address.
              Please follow the link in your email to reset your password.
            </p>

            <p>After resetting your password, return to the login page.</p>

            <div class="mt-6">
              <v-btn small dark color="green darken-4" :to="{ name: 'login' }">
                Return to Log In
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="errorCode">
      <v-col class="text-center red--text caption">
        <pre>{{ errorCode }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';

export default {
  mounted() {
    this.sent = false;
  },
  data() {
    return {
      sending: false,
      sent: false,
      email: '',
      errorCode: '',
      errorMessage: '',
    };
  },
  methods: {
    send() {
      const vm = this;
      vm.errorCode = '';
      vm.errorMessage = '';

      if (!this.$refs.form.validate()) {
        return;
      }

      vm.sending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email, {
          url:
            'https://admin.villagefreeschool.org/reset-password?email=' +
            this.email,
        })
        .then((result) => {
          this.sent = true;
        })
        .catch((error) => {
          vm.errorCode = error.code;
          vm.errorMessage = error.message;
        })
        .finally(() => {
          vm.sending = false;
        });
    },
  },
};
</script>

<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :md="md" :sm="sm" :xs="xs">
    <div class="caption">{{ label }}</div>
    <div :class="myClass">
      <slot></slot>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    cols: {
      type: [String, Number],
    },
    xl: {
      type: [String, Number],
    },
    lg: {
      type: [String, Number],
    },
    md: {
      type: [String, Number],
    },
    sm: {
      type: [String, Number],
    },
    xs: {
      type: [String, Number],
    },
    label: String,
    error: Boolean,
    warning: Boolean,
  },
  computed: {
    myClass() {
      return {
        'subtitle-1': true,
        'preserve-whitespace': true,
        'red--text': !!this.error,
        'orange--text': !!this.warning,
      };
    },
  },
};
</script>

<template>
  <div>
    <v-row justify="space-around" dense>
      <v-col cols="12">
        <v-text-field
          label="Doctor/Contact Name"
          v-model.trim="local.name"
          @input="update('name', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Clinic / Office"
          v-model.trim="local.office"
          @input="update('office', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Phone Number"
          v-mask="'###-###-####'"
          v-model="local.phoneNumber"
          @input="update('phoneNumber', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';

export default {
  props: ['value'],
  data() {
    return {};
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            name: '',
            phoneNumber: '',
          };
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {},
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title>School Years</v-toolbar-title>
            <template v-slot:extension>
              <v-btn
                fab
                absolute
                bottom
                left
                color="brown"
                dark
                @click="newYear()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :items="sortedYears"
              :headers="headers"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td class="text-right">
                      {{ item.minimumTuition | currency }}
                    </td>
                    <td class="text-right">
                      {{ item.maximumTuition | currency }}
                    </td>
                    <td class="text-center">
                      <v-icon
                        small
                        v-if="item.isAcceptingRegistrations"
                        color="green darken-4"
                        >fa-check</v-icon
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        x-small
                        dark
                        color="brown"
                        :to="{ name: 'yearContracts', params: { id: item.id } }"
                      >
                        <v-icon x-small left>fa-file-contract</v-icon>
                        Contracts
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        x-small
                        dark
                        color="brown"
                        :to="{ name: 'yearRoster', params: { id: item.id } }"
                      >
                        <v-icon x-small left>fa-list-ul</v-icon>
                        Roster
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        x-small
                        color="brown"
                        dark
                        @click="editYear(item.id)"
                      >
                        <v-icon x-small left>fas fa-edit</v-icon>
                        Edit
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <YearNewDialog ref="newDialog" />
    <YearEditDialog ref="editDialog" />
  </v-container>
</template>

<script lang="js">
import _ from 'lodash';
import { mapState } from 'vuex';

// import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

import YearNewDialog from '@/components/YearNewDialog.vue';
import YearEditDialog from '@/components/YearEditDialog.vue';

export default {
  created() {
    this.fetchData();
  },
  data() {
    return {
      years: [],
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'minimumTuition', text: 'Min Tuition', align: 'right' },
        { value: 'maximumTuition', text: 'Max Tuition', align: 'right' },
        { value: 'isAcceptingRegistrations', align: 'center', text: 'Registration Open' },
        { value: null, text: 'Contracts', align: 'center', sortable: false },
        { value: null, text: 'Roster', align: 'center', sortable: false },
        { value: null, text: 'Edit', align: 'center', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    sortedYears() {
      return _.chain(this.years || []).sortBy('name').reverse().value();
    }
  },
  methods: {
    newYear() {
      this.$refs.newDialog.open();
    },
    editYear(id) {
      this.$refs.editDialog.open(id);
    },
    fetchData() {
      this.loading = true;
      this.$bind('years', db.collection('years')).then(() => {
        this.loading = false;
      })
    },
  },
  components: {
    YearNewDialog,
    YearEditDialog,
  }
};
</script>

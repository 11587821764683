<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title>Families</v-toolbar-title>
            <v-spacer />
            <v-text-field
              v-model="search"
              single-line
              hide-details
              label="Search"
            />
            <template v-if="isAdmin" v-slot:extension>
              <v-btn
                fab
                absolute
                bottom
                left
                color="brown"
                dark
                @click="newFamily()"
                v-if="!loading"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </template>
          </v-toolbar>

          <v-card-text v-if="loading">
            <v-progress-linear indeterminate />
          </v-card-text>

          <v-card-text v-if="!loading">
            <v-data-table
              :items="sortedFamilies"
              :headers="headers"
              :search="search"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <router-link
                        :to="{ name: 'familyShow', params: { id: item.id } }"
                      >
                        {{ item.familyName }}
                      </router-link>
                    </td>
                    <td>{{ item.studentNames }}</td>
                    <td>{{ item.authorizedEmails.join(', ') }}</td>
                    <td>
                      <v-btn
                        x-small
                        color="brown"
                        dark
                        :to="{
                          name: 'familyRegistration',
                          params: { id: item.id },
                        }"
                      >
                        <v-icon x-small left>fa-file-contract</v-icon>
                        Registrations
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        x-small
                        color="brown"
                        dark
                        @click="editFamily(item.id)"
                      >
                        <v-icon x-small left>fas fa-edit</v-icon>
                        Edit Family
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FamilyNewDialog
      ref="newDialog"
      @saved="
        $set(familyByID, $event.id, $event);
        familyIDs.push($event.id);
      "
    />
    <FamilyEditDialog
      ref="editDialog"
      @saved="$set(familyByID, $event.id, $event)"
    />
  </v-container>
</template>

<script lang="js">
import _ from 'lodash';

import { mapGetters, mapState } from 'vuex';

// import firebase from 'firebase/app';
import { db, calculatedNameForFamily, guardianNamesForFamily } from '@/lib/firestoredb';

import FamilyNewDialog from '@/components/FamilyNewDialog.vue';
import FamilyEditDialog from '@/components/FamilyEditDialog.vue';

export default {
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      search: '',
      families: [],
      headers: [
        { value: 'familyNameAndGuardians', text: 'Family Name' },
        { value: 'studentNames', text: 'Students'},
        { value: 'authorizedEmails', text: 'Authorized Emails' },
        { value: null, text: 'Registrations', sortable: false },
        { value: null, text: 'Edit', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(['myFamily']),
    ...mapGetters(['isAdmin']),

    sortedFamilies() {
      return _.chain(this.families || [])
        .map((family) => {
          const studentNames = family.students.map((student) => student.preferredName).join(', ');
          const calculatedName = calculatedNameForFamily(family);
          const guardianNames = family ? guardianNamesForFamily(family) : '';
          return Object.assign(family, {
            ...family,
            familyName: calculatedName ? calculatedName : family.name,
            familyNameAndGuardians: `${calculatedName} (${guardianNames})`,
            studentNames,
          });
        })
        .sortBy('familyNameAndGuardians')
        .value();
    },

  },
  methods: {

    newFamily() {
      this.$refs.newDialog.open();
    },

    editFamily(id) {
      this.$refs.editDialog.open(id);
    },

    fetchData() {
      const vm = this;
      vm.loading = true;
      this.$bind('families', db.collection('families')).
        then(() => {
          vm.loading = false;
        });
    },
  },
  components: {
    FamilyNewDialog,
    FamilyEditDialog,
  },
};
</script>

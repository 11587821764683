<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-form ref="form" @submit.prevent="save()">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>Edit User {{ user && user.email }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="loading">
          <v-progress-linear indeterminate />
        </v-card-text>
        <v-card-text v-if="!loading">
          <UserForm
            v-if="isOpen && user"
            v-model="user"
            :allow-changing-email="false"
            @canceled="$emit('input', false)"
            @saved="$emit('saved', user)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserForm from '@/components/UserForm.vue';

// import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      isOpen: false,
      loading: false,
      saving: false,
      user: null,
    };
  },
  methods: {
    open(email) {
      const vm = this;
      vm.isOpen = true;
      vm.loading = true;
      db.collection('users')
        .doc(email)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const user = doc.data();
            user.email = doc.id.toString();
            vm.user = user;
            vm.loading = false;
          } else {
            vm.isOpen = false;
            console.log('No such document: ', email);
          }
        })
        .catch(function (error) {
          vm.isOpen = false;
          console.log('Error getting document:', error);
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const vm = this;
      vm.saving = true;
      db.collection('users')
        .doc(vm.user.email)
        .set(vm.user)
        .then(() => {
          vm.$emit('saved', vm.user);
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    UserForm,
  },
};
</script>

import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import 'firebase/auth';
import 'firebase/firestore';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import { db } from '@/lib/firestoredb';

Vue.use(Vuex);

// exponentTransform returns 0 > y > 1, such that steepness
// of 1 is a 45-degree linear line between 0,0 and 1,1, and
// higher steepnesses curve symmetrically towards the 1,0 corner
//
function exponentTransform(x, steepness) {
  const adjustedSteepness = steepness + 0.01; // Needed to avoid exactly equalling 1.
  const numerator = Math.pow(adjustedSteepness, x) - 1;
  const denominator = adjustedSteepness - 1;
  return numerator / denominator;
}

export function povertyGuideline(householdSize) {
  if (householdSize <= 1) {
    return 12490;
  } else if (householdSize == 2) {
    return 16910;
  } else if (householdSize == 3) {
    return 21330;
  } else if (householdSize == 4) {
    return 25750;
  } else if (householdSize == 5) {
    return 30170;
  } else if (householdSize == 6) {
    return 34590;
  } else if (householdSize == 7) {
    return 39010;
  } else if (householdSize == 8) {
    return 43430;
  } else if (householdSize > 8) {
    return 43430 + (householdSize - 8) * 4420;
  }
}

export default new Vuex.Store({
  state: {
    awaitingFirebaseAuth: true,

    userFamily: null,
    myFamily: null,

    loggedIn: false,
    user: null,

    years: [],

    legacyBaseTuition: 8400,
    siblingDiscountFactor: 0.5,
    partTimeDiscountFactor: 0.625,
    steepness: 1.56,
    allowSiblingDiscountForPartTime: false,
    minimumTuition: 1000,
    maximumTuition: 12500,
    minimumIncome: 28000,
    maximumIncome: 120000,
    maxYearOverYearChange: 0.1,
    inflationIncrease: 250,
  },
  getters: {
    isAdmin(state) {
      return state.user && state.user.isAdmin;
    },
    myFamilyID(state) {
      if (state.userFamily && state.userFamily.id) {
        return state.userFamily.familyID;
      }
      return null;
    },

    /**
     * priorYear searches the list of school years from Firestore for the
     * one matching the submitted year ID, and returns the next (earlier)
     * entry if it exists.
     */
    priorYear: (state, getters) => (yearID) => {
      const index = _.findIndex(getters.sortedYears, (y) => y.id == yearID);
      if (index == -1) {
        // Didn't find the referenced year
        return null;
      }
      if (index >= getters.sortedYears.length - 1) {
        // We're at the last year already
        return null;
      }
      return getters.sortedYears[index + 1];
    },

    /**
     * sortedYears takes the bound list of years from the Firestore DB
     * and sorts them in reverse order by their start dates.
     */
    sortedYears(state) {
      const years = _.sortBy(state.years || [], 'name');
      years.reverse();
      return years;
    },

    /**
     * DELETE
     *
     */
    maximumTuitionForYear: (state) => (year) => {
      if (!year) {
        year = 0;
      }
      return state.maximumTuition + year * state.inflationIncrease;
    },

    /**
     * DELETE
     */
    baseTuitionForFamilyInYear: (state) => (family, year) => {
      if (!year || !family) {
        return null;
      }
      const income = family.grossFamilyIncome || state.maximumIncome;
      if (income <= state.minimumIncome) {
        return year.minimumTuition;
      }
      if (income >= state.maximumIncome) {
        return year.maximumTuition;
      }
      const incomeRange = state.maximumIncome - state.minimumIncome;
      const tuitionRange = year.maximumTuition - year.minimumTuition;
      const x = (income - state.minimumIncome) / incomeRange;
      const y = exponentTransform(x, state.steepness);
      return state.minimumTuition + tuitionRange * y;
    },
    /**
     * DELETE
     *
     * baseTuitionForIncome is the heart of the tuition calculation algorithm.
     * Given an income, it returns the "base tuition" (the tuition for single
     * full-time student). The part-time and sibling tuitions are then
     * calculable from the base tuition number.
     */
    baseTuitionForIncome: (state, getters) => (income, year) => {
      // Ensure year is numeric
      if (!year) {
        year = 0;
      }

      if (income <= state.minimumIncome) {
        return state.minimumTuition;
      }
      if (income >= state.maximumIncome) {
        return getters.maximumTuitionForYear(year);
      }
      return getters.baseTuitionForIncomeDirect(income, year);
    },
    siblingTuitionForIncome: (state, getters) => (income, year) => {
      return (
        getters.baseTuitionForIncome(income, year) * state.siblingDiscountFactor
      );
    },
    partTimeTuitionForIncome: (state, getters) => (income, year) => {
      return (
        getters.baseTuitionForIncome(income, year) *
        state.partTimeDiscountFactor
      );
    },
    baseTuitionForIncomeDirect: (state, getters) => (income, year) => {
      if (!year) {
        year = 0;
      }
      const incomeRange = state.maximumIncome - state.minimumIncome;
      const tuitionRange =
        getters.maximumTuitionForYear(year) - state.minimumTuition;
      const x = (income - state.minimumIncome) / incomeRange;

      // y is a value between 0 and 1 representing where the
      // tuition should fall between the max and min
      // const y = linearTransform(x);
      const y = exponentTransform(x, state.steepness);
      return state.minimumTuition + tuitionRange * y;
    },
  },
  mutations: {
    ...vuexfireMutations,

    setPropToValue(state, payload) {
      Vue.set(state, payload.prop, payload.value);
    },

    /**
     * setUser handles the callback from Firebase Auth and
     * updates the state with the detected user.
     */
    setUser(state, user) {
      state.awaitingFirebaseAuth = false;
      state.user = user;
      if (user && user.uid) {
        state.loggedIn = !user.isAnonymous;
      }
    },

    /**
     * setUserLoggedOut wipes the currently logged-in user's profile
     * and uid info.
     */
    setUserLoggedOut(state) {
      state.awaitingFirebaseAuth = false;
      state.loggedIn = false;
      state.user = null;
    },

    startingAuth(state) {
      state.awaitingFirebaseAuth = true;
    },
  },
  actions: {
    bindYears: firestoreAction(({ state, bindFirestoreRef }) => {
      state.yearsLoaded = false;
      return bindFirestoreRef('years', db.collection('years')).then(() => {
        state.yearsLoaded = true;
      });
    }),

    bindUserFamily: firestoreAction(({ bindFirestoreRef, state }, email) => {
      return bindFirestoreRef(
        'userFamily',
        db.collection('userFamilies').doc(email.toLowerCase()),
        { maxRefDepth: 0 }
      ).then(() => {
        if (state.userFamily && state.userFamily.familyID) {
          return bindFirestoreRef(
            'myFamily',
            db.collection('families').doc(state.userFamily.familyID),
            { maxRefDepth: 0 }
          );
        }
        return Promise.resolve();
      });
    }),

    awaitAuth({ state }) {
      const recheckDelay = 200;
      const maxChecks = 10;
      return new Promise((resolve, reject) => {
        let checkCount = 0;
        const userChecker = () => {
          checkCount++;
          if (state.awaitingFirebaseAuth) {
            if (checkCount <= maxChecks) {
              setTimeout(userChecker, recheckDelay);
            } else {
              reject();
            }
          } else {
            resolve();
          }
        };
        userChecker();
      });
    },
  },
});

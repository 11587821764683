<template>
  <v-row dense>
    <v-col cols="6" v-if="isLocked">
      <v-text-field disabled :label="label" value="Previously Entered" />
    </v-col>
    <v-col v-if="!isLocked">
      <v-currency-field
        ref="incomeField"
        :label="label"
        :value="displayedValue"
        @input="update($event)"
        prefix="$"
      />
    </v-col>
    <v-col cols="6" v-if="isLocked" class="d-flex align-center">
      <v-btn color="brown" dark x-small @click="unlock()">
        <v-icon left x-small>fa-edit</v-icon>
        Edit
      </v-btn>
    </v-col>
    <v-col
      v-if="!isLocked && this.initialValue"
      cols="6"
      class="d-flex justify-space-around align-center"
    >
      <v-btn
        v-if="displayedValue != initialValue"
        color="green darken-4"
        dark
        x-small
        @click="confirmEdit()"
      >
        <v-icon left x-small>fa-check</v-icon>
        OK
      </v-btn>
      <v-btn color="red" dark x-small @click="cancelEdit()">
        <v-icon left x-small>fa-ban</v-icon>
        Cancel
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['value', 'label'],
  created() {
    this.initialValue = this.value;
    this.displayedValue = this.value;
    if (this.value) {
      this.isLocked = true;
    } else {
      this.isLocked = false;
    }
  },
  data() {
    return {
      isLocked: false,
      initialValue: null,
      displayedValue: null,
    };
  },
  watch: {
    value(newVal) {
      this.displayedValue = newVal;
    },
  },
  methods: {
    unlock() {
      this.displayedValue = null;
      this.isLocked = false;
    },
    cancelEdit() {
      this.isLocked = true;
      this.displayedValue = null;
      this.$emit('input', this.initialValue);
    },
    confirmEdit() {
      this.initialValue = this.displayedValue;
      this.$emit('input', this.displayedValue);
      this.isLocked = true;
    },
    update(newValue) {
      if (newValue) {
        this.$emit('input', newValue);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>

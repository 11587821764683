<template>
  <v-row justify="start">
    <v-col cols="12">
      <v-text-field
        ref="name"
        label="Name"
        v-model.trim="local.name"
        :rules="[(v) => !!v || 'Required']"
        @input="update('name', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-currency-field
        label="Minimum Tuition"
        prefix="$"
        v-model.number="local.minimumTuition"
        :rules="[(v) => !!v || 'Required']"
        @input="update('minimumTuition', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-currency-field
        label="Maximum Tuition"
        prefix="$"
        v-model.number="local.maximumTuition"
        :rules="[(v) => !!v || 'Required']"
        @input="update('maximumTuition', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-currency-field
        label="Minimum Income"
        prefix="$"
        v-model.number="local.minimumIncome"
        :rules="[(v) => !!v || 'Required']"
        @input="update('minimumIncome', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-currency-field
        label="Maximum Income"
        prefix="$"
        v-model.number="local.maximumIncome"
        :rules="[(v) => !!v || 'Required']"
        @input="update('maximumIncome', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-checkbox
        label="Registration Open"
        v-model="local.isAcceptingRegistrations"
        @input="update('isAcceptingRegistrations', $event)"
      />
      <span class="caption"> Enables the registration link for families. </span>
    </v-col>
  </v-row>
</template>

<script>
import {
  DefaultMinimumIncome,
  DefaultMaximumIncome,
  DefaultMinimumTuition,
  DefaultMaximumTuition,
} from '@/lib/tuitioncalc';
import { cloneDeep, tap, set } from 'lodash';

export default {
  props: {
    value: {
      type: Object,
    },
  },
  mounted() {
    this.$refs.name.focus();
  },
  data() {
    return {};
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            name: '',
            minimumTuition: DefaultMinimumTuition,
            maximumTuition: DefaultMaximumTuition,
            minimumIncome: DefaultMinimumIncome,
            maximumIncome: DefaultMaximumIncome,
            isAcceptingRegistrations: false,
            isAcceptingIntentToReturns: false,
          };
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {},
};
</script>

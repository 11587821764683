<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="12" sm="4">
        <v-text-field
          label="First Name"
          v-model="local.firstName"
          @blur="updatePreferredName()"
          :rules="[(v) => !!v || 'Required']"
          @input="update('firstName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Middle Name"
          v-model="local.middleName"
          @input="update('middleName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Last Name"
          v-model="local.lastName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('lastName', $event)"
        />
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" sm="6">
        <v-text-field
          label="Preferred Name"
          v-model="local.preferredName"
          :rules="[(v) => !!v || 'Required']"
          @input="update('preferredName', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-dialog
          v-model="birthdateDialog"
          :close-on-content-click="true"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="local.birthdate"
              label="Birthdate"
              persistent-hint
              readonly
              v-on="on"
              :rules="[(v) => !!v || 'Required']"
              @input="update('birthdate', $event)"
            ></v-text-field>
          </template>
          <v-date-picker
            color="green darken-4"
            v-model="local.birthdate"
            :rules="[(v) => !!v || 'Required']"
            @input="birthdateDialog = false"
          ></v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col>
        <v-select
          label="Gender"
          v-model="local.gender"
          :items="genders"
          :rules="[(v) => !!v || 'Required']"
          @input="update('gender', $event)"
        >
        </v-select>
      </v-col>
      <v-col v-if="local.gender === 'Custom'">
        <v-text-field
          label="Gender"
          v-model="local.customGender"
          @input="update('customGender', $event)"
        />
      </v-col>
      <v-col>
        <v-select
          label="Pronoun"
          v-model="local.pronoun"
          :items="pronouns"
          :rules="[(v) => !!v || 'Required']"
          @input="update('pronoun', $event)"
        />
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" sm="6">
        <v-text-field
          label="Email Address (Optional)"
          :hint="'Enter only if ' + local.preferredName + ' uses email.'"
          v-model="local.email"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Prior School"
          v-model="local.priorSchool"
          :hint="
            'School ' +
            local.preferredName +
            ' most recently attended before VFS.'
          "
          @input="update('priorSchool', $event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-textarea
          label="Learning Disabilities"
          v-model="local.learningDisabilities"
          rows="3"
          @input="update('learningDisabilities', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          label="Additional Information"
          v-model="local.additionalInfo"
          rows="3"
          @input="update('additionalInfo', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          label="Severe Allergies"
          v-model="local.severeAllergies"
          rows="3"
          hint="Note allergies with anaphylaxis or other severe impacts"
          @input="update('severeAllergies', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          label="Non-Severe Allergies"
          v-model="local.nonSevereAllergies"
          rows="3"
          hint="Note dietary intolerances, seasonal allergies and other allergies with non-severe impacts"
          @input="update('nonSevereAllergies', $event)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          label="Other Medical Conditions"
          rows="3"
          v-model="local.otherMedicalConditions"
          @input="update('otherMedicalConditions', $event)"
        />
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <v-switch
          :label="
            'I authorize the use of photographs or other media recordings of ' +
            shortName
          "
          v-model="local.mediaRelease"
          @input="update('mediaRelease', $event)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-switch
          :label="
            'I authorize ' +
            shortName +
            ' to sign out of school ' +
            reflexivePronoun
          "
          v-model="local.signSelfOut"
          @input="update('signSelfOut', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';

export default {
  props: ['value'],
  data() {
    return {
      birthdateDialog: false,
      genders: ['Female', 'Male', 'Rather Not Say', 'Custom'],
      pronouns: ['He', 'She', 'They', 'Rather Not Say'],
    };
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            firstName: '',
            middleName: '',
            lastName: '',
            preferredName: '',
            mediaRelease: true,
          };
    },
    shortName() {
      if (!this.local) {
        return 'this child';
      }
      const n = this.local.preferredName || this.local.firstName;
      return n;
    },
    reflexivePronoun() {
      if (!this.local || !this.local.pronoun) {
        return 'themself';
      }
      switch (this.local.pronoun) {
        case 'He':
        case 'he':
        case 'His':
        case 'his':
          return 'himself';
        case 'She':
        case 'she':
        case 'Her':
        case 'her':
          return 'herself';
        case 'They':
        case 'they':
          return 'themself';
        default:
          return 'themself';
      }
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
    updatePreferredName() {
      if (!this.local.preferredName && this.local.firstName) {
        this.update('preferredName', this.local.firstName);
      }
    },
  },
};
</script>

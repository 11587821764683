<template>
  <v-row justify="start">
    <v-col cols="12">
      <v-text-field
        ref="email"
        label="Email Address"
        hint="They will log in to the system using this address."
        :disabled="!allowChangingEmail"
        v-model.trim="local.email"
        :rules="[(v) => !!v || 'Required']"
        @input="update('email', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        ref="firstName"
        label="First Name"
        v-model.trim="local.firstName"
        :rules="[(v) => !!v || 'Required']"
        @input="update('firstName', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="Last Name"
        v-model.trim="local.lastName"
        :rules="[(v) => !!v || 'Required']"
        @input="update('lastName', $event)"
      />
    </v-col>
    <v-col cols="6">
      <v-checkbox
        label="Administrator"
        v-model="local.isAdmin"
        @input="update('isAdmin', $event)"
      />
      <span class="caption">
        Administrators can see all data and can create new users.
      </span>
    </v-col>
    <v-col cols="6">
      <v-checkbox
        label="Staff"
        v-model="local.isStaff"
        @input="update('isStaff', $event)"
      />
      <span class="caption"> Staff have access to the backend area. </span>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';

export default {
  props: {
    value: {
      type: Object,
    },
    allowChangingEmail: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.allowChangingEmail) {
      this.$nextTick(() => {
        this.$refs.email.focus();
      });
    } else {
      this.$refs.firstName.focus();
    }
  },
  data() {
    return {};
  },
  computed: {
    local() {
      return this.value
        ? this.value
        : {
            firstName: '',
            lastName: '',
            email: '',
            isAdmin: false,
            isStaff: false,
          };
    },
    isAdmin() {
      return this.local.isAdmin;
    },
  },
  watch: {
    isAdmin(newVal) {
      if (newVal) {
        this.update('isStaff', true);
      }
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    },
  },
  components: {},
};
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-form ref="form" @submit.prevent="createAccount">
          <v-card>
            <v-toolbar color="green darken-4" dark>
              <v-toolbar-title>Create a VFS Portal Account</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="errorMessage">
                {{ errorMessage }}
                <span v-if="errorCode == 'auth/email-already-in-use'">
                  <router-link class="white--text" to="/login"
                    >Login</router-link
                  >
                  instead.
                </span>
              </v-alert>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Email Address"
                    v-model="email"
                    :rules="[(v) => !!v || 'Required']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password"
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password Confirmation"
                    v-model="passwordConfirmation"
                    :rules="passwordConfirmationRules"
                    type="password"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn small text color="error" @click="cancel()">Cancel</v-btn>
              <v-btn color="green darken-4" dark type="submit">
                <v-icon left>fa-plus-square</v-icon>Create Account
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="errorCode">
      <v-col class="text-center red--text caption">
        <pre>{{ errorCode }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import firebase from 'firebase/app';

export default {
  mounted() {
    // var firebaseui = require('firebaseui');
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      errorCode: '',
      errorMessage: '',
    };
  },
  computed: {
    passwordRules() {
      return [
        (v) => !!v || 'Required',
        (v) => (v || '').length >= 6 || 'Passwords must be at least 6 characters',
      ];
    },
    passwordConfirmationRules() {
      return [
        (v) => (v === this.password) || 'Must match password'
      ];
    }
  },
  methods: {
    cancel() {
      this.$router.push('/login');
    },
    createAccount() {
      const vm = this;
      vm.errorCode = '';
      vm.errorMessage = '';
      if (!vm.$refs.form.validate()) {
        return;
      }

      firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
      .then(() => {
        vm.$router.push('/');
      })
      .catch((error) => {
        vm.errorCode = error.code;
        vm.errorMessage = error.message;
      });
    },
  }
}
</script>

<template>
  <v-container>
    <v-row v-if="loading">
      <v-col> <v-progress-linear /> </v-col>
    </v-row>
    <v-row v-if="!loading && family">
      <v-col cols="12" v-for="year in displayedYears" :key="year.id">
        <v-card>
          <v-toolbar color="green darken-4" dark>
            <v-toolbar-title>
              {{ family.name }} Registration For
              {{ year.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="!family || !family.students">
            No students in your family.
          </v-card-text>
          <v-card-text v-else>
            <v-alert
              v-if="!year.isAcceptingRegistrations"
              type="info"
              dense
              color="grey"
            >
              NOTE: This is only visible to administrators because registrations
              are not being accepted for {{ year.name }}.
            </v-alert>
            <Contract :familyID="family.id" :yearID="year.id" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { familyDB, yearDB } from '@/lib/firestoredb';

import Contract from '@/components/Contract.vue';

export default {
  props: ['id'],
  data() {
    return {
      loading: false,
      propFamily: null,
      years: [],
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin', 'priorYear']),

    ...mapState(['myFamily']),
    family() {
      return this.propFamily || this.myFamily;
    },
    displayedYears() {
      return this.years.filter((year) => {
        return year.isAcceptingRegistrations || this.isAdmin;
      });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        if (id) {
          this.loading = true;
          this.$bind('propFamily', familyDB.doc(id)).then(() => {
            this.loading = false;
          });
        }
      },
    },
  },
  firestore: {
    years: yearDB.orderBy('name', 'desc'),
  },
  components: {
    Contract,
  },
};
</script>

<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-form ref="form" @submit.prevent="save()">
      <v-card>
        <v-toolbar color="green darken-4" dark>
          <v-toolbar-title>New School Year</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <YearForm
            v-if="isOpen && year"
            v-model="year"
            @canceled="$emit('input', false)"
            @saved="$emit('saved', year)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="error" @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn color="brown" dark :loading="saving" type="submit">
            <v-icon left>fas fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import YearForm from '@/components/YearForm.vue';

// import firebase from 'firebase/app';
import { db } from '@/lib/firestoredb';

export default {
  props: ['value'],
  data() {
    return {
      saving: false,
      isOpen: false,
      year: null,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      this.year = {};
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const vm = this;
      vm.saving = true;
      db.collection('years')
        .add(vm.year)
        .then((docRef) => {
          vm.year.id = docRef.id;
          vm.$emit('saved', vm.year);
          vm.isOpen = false;
        })
        .catch((error) => {
          alert('Error');
          console.log(error);
        })
        .finally(() => {
          vm.saving = false;
        });
    },
  },
  components: {
    YearForm,
  },
};
</script>

<template>
  <v-dialog v-model="isOpen" :loading="loading" width="600px">
    <template v-slot:activator="{ on }">
      <v-btn small color="red" dark v-on="on">
        <v-icon small left>fa-trash</v-icon>
        Delete Family
      </v-btn>
    </template>

    <v-card v-if="!loading && family">
      <v-toolbar color="red" dark>
        <v-toolbar-title>Delete Family</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            Are you sure you want to delete '{{ family.name }}'. This will
            remove all students, contracts, enrollment records and the family
            itself from the database.
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn small text color="green darken-4" dark @click="close()">
          Cancel
        </v-btn>
        <v-btn color="error" :loading="deleting" dark @click="executeDelete()">
          <v-icon left>fa-trash</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import router from '@/router';
import { familyDB, deleteFamily } from '@/lib/firestoredb';

export default {
  props: ['familyID'],
  data() {
    return {
      isOpen: false,
      loading: false,
      family: null,
      deleting: false,
    };
  },
  watch: {
    familyID: {
      immediate: true,
      handler() {
        this.bindFamily();
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    bindFamily() {
      this.loading = true;
      let familyID = this.familyID;
      if (familyID) {
        this.$bind('family', familyDB.doc(familyID)).then(() => {
          this.loading = false;
        });
      }
    },
    executeDelete() {
      this.deleting = true;
      deleteFamily(this.family).then(() => {
        router.push({ name: 'familyList' });
        this.close();
      });
    },
  },
};
</script>
